import { Component, OnInit } from '@angular/core';
import { CountryService } from '../../core/country.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '../../translate';
import * as $ from 'jquery';


@Component({
  selector: 'app-dreamcation',
  templateUrl: './dreamcation.component.html',
  styleUrls: ['./dreamcation.component.css']
})
export class DreamcationComponent implements OnInit {
  zone: string;
  sponsor: string;
  sID: string;
  active: string;
  points: string;
  membership: string;
  email: string;
  numberleft: string;
  showFeaturedList: boolean;
  showSingleItem: boolean;
  showactive: boolean;

  

  constructor(		
    private cserv: CountryService,
		private route: ActivatedRoute,
    private router: Router,) { 

      this.route.params.subscribe((routeParams) => {
        this.zone = routeParams['zone']
        this.sponsor = routeParams['sponsor']
        this.active = routeParams['active']
        this.points = routeParams['points']
        this.membership = routeParams['eligible_membership_level']
        this.email = routeParams['email']
        this.numberleft = routeParams['numberleft']
      });
    }
    private getTagsHtml(tagName: keyof HTMLElementTagNameMap): string
    {
        const htmlStr: string[] = [];
        const elements = document.getElementsByTagName(tagName);
        for (let idx = 0; idx < elements.length; idx++)
        {
            htmlStr.push(elements[idx].outerHTML);
        }
    
        return htmlStr.join('\r\n');
    }
  ngOnInit() {
    this.showFeaturedList = true;
    this.showSingleItem = false;
    if (this.active) {
      this.showactive = true;
    };


    
    $(document).ready(() => {
      function senduplocation() {
        $("#showFeaturedList").hide('slow');
        $("#showSingleItem").show('slow');
    
      }
      function senduplist() {
        $("#showFeaturedList").show('slow');
        $("#showSingleItem").hide('slow');
    
      }
      
      $('.divarclick').click(function() {
        event.preventDefault();
        senduplocation();
        $('html, body').animate({
          scrollTop: $("#spanbox").offset().top - 100}, 1000);
        var currentval = $(this).attr("value");
        var currelement = $(this);
        $('.choice').each(function(){
          var elementid = $(this).attr('id');
          if(elementid == currentval) {
            $(this).show('slow');
          } else {
            $(this).hide();
          }
      });
      $('.backtolist').click(function() {
        event.preventDefault();
        senduplist();
      });
    });


  });
 
    
  }

  loginlink(theid) {
    window.location.href = "http://backoffice.paid2save.com/Paid2SaveNetwork/dream_login.asp?id="+theid+"&hash=ec351c7c-b587-43cf-8545-0ec7385eb608";
  }

  senduptherest(thestate) {
    //$("#"+thestate).show();
    this.showFeaturedList = true;
    this.showSingleItem = false;
    
    $(".fullstate").hide();
  }

  letsprint() {
    const printContent = document.getElementById("dcpr").innerHTML;
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    const stylesHtml = this.getTagsHtml('style');
    const linksHtml = this.getTagsHtml('link');
    WindowPrt.document.write(`
        <html>
            <head>
                <title>Print tab</title>
                ${linksHtml}
                ${stylesHtml}
            </head>
            <body onload="window.print(); window.close()">
                ${printContent}
            </body>
        </html>
        `
    );
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }

}
