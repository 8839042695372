import { Component, OnInit } from '@angular/core';
import { WorldtourService } from '../../core/worldtour.service';
import { IdService } from '../../core/id.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '../../translate';
import * as $ from 'jquery';

@Component({
    selector: 'app-trip',
    templateUrl: './trip.component.html',
    styleUrls: ['./trip.component.css']
})
export class TripComponent implements OnInit {
    id: any;
    tour: any;
    trip: any;
    valid: boolean = true;
    children: any[] = [];

    constructor(
        private route: ActivatedRoute,
        private wts: WorldtourService,
        private auth: IdService,
        private router: Router
    ) {
        this.route.queryParams.subscribe(params => {
            if (params['id'] && params['hash']) {
                this.id = params['id'];
                if (!this.auth.verify(this.id, params['hash'])) {
                    window.location.replace("https://backoffice.travorium.com/travorium/member_new2/welcome.asp");
                }
                console.log('Pass Verification');

            } else if (auth.salted) {
                console.log('Previously Salted');
                this.id = this.auth.id
            } else {
                window.location.replace("https://backoffice.travorium.com/travorium/member_new2/welcome.asp");
            };

            this.route.params.subscribe((prams) => {
                if (prams['tour-id']) {
                    this.tour = this.wts.serveTour(prams['tour-id'])
                    if (prams['trip-id']) {
                        this.trip = this.serveTrip(prams['trip-id'])
                    }
                }
            })
        });
    }

    ngOnInit() {
        this.children = [];
        this.valid = true;
    }

    serveTrip(trip_id: string) {
        return this.tour.trips.find(trip => trip.trip_id == trip_id)
    }

    addChild() {
        console.log(this.trip);
        this.children.push(1);
        console.log(this.children);
        //this.valid = false
        return this.children.length
    }

    removeChild(i: number, maxAge: number) {
        this.children.splice(i, 1);

        let flag = true;
        for (let child of this.children) {
            if (child > maxAge) { flag = false }
        }
        this.valid = flag;

        return this.children.length
    }

    checkChild(event: number, maxAge: number) {
        console.log(event);
        if (!event) {
            this.valid = false;
            return false;
        }
        if (event > maxAge) {
            this.valid = false;
            return false;
        }
        this.valid = true;
        return true
    }

    peoplewatcher(room: any) {
        if (room.child < 0) {
            console.log(room);
            room.child = 0;
        }
        if (room.adults < 0) {
            room.adults = 0;
        }
        if (room.adults > room.maxadults) {
            room.adults = room.maxadults;
        }
        if (Number(room.child) + Number(room.adults) > room.maxpeople) {
            return false
        } else {
            return true
        }
    }

    calculatepointsused(x, y) {
        let a = x - y;
        let pointvalue = a * 2;
        return pointvalue;
    }

    switchTrip(trip_id: string) {
        return this.router.navigateByUrl(`/tour/${this.tour.tour_id}/${trip_id}`)
    }
}
