import { Component, OnInit, ViewChild } from '@angular/core';
import { CountryService } from '../../core/country.service';
import { NgbModal, NgbActiveModal, NgbModalRef, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import {  FormBuilder, FormGroup } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable } from 'rxjs';
import { SponsorService } from '../../core/sponsor.service';
import { TranslateService, TranslatePipe } from '../../translate';


@Component({
  selector: 'app-cc-modal',
  templateUrl: './cc-modal.component.html',
  styleUrls: ['./cc-modal.component.css']
})
export class CcModalComponent implements OnInit {
  @ViewChild('cpicker') content: any;
  @ViewChild('calText') calText: any;
  modalRef: NgbModalRef;
  calRef: NgbModalRef;
  zones$: Observable<any[]>;
  zones: any[];
  countrypicker: boolean;

  constructor(
    private cserv: CountryService,
    private modalService: NgbModal,
    private db: AngularFirestore,
    private afs: AngularFireFunctions,
    private _translate: TranslateService,
  ) {
    //this.pickCountry()

    this.cserv.opener.subscribe((toggle) => {
      if (toggle) {
        this.modalRef = this.modalService.open(this.content, {'centered': true, size: 'lg'})
        this.cserv.openerOff()
      } else {
        console.log('no opener')
      }
    })

    this.cserv.caListener.subscribe((toggle) => {
      if (toggle) {
        this.calRef=this.modalService.open(this.calText,{'centered': true})
        this.cserv.caOff()
      }
    })

    const shortRef = this.db.collection(`short_zones`)
    shortRef.valueChanges().subscribe(zns => {
      // console.log(zns)
      if (zns) {
        this.zones = zns
      }

    })
    // console.log(this.zones)

  }

  ngOnInit() {
  }

  async pickCountry() {
    const ccheck = this.afs.httpsCallable('countryCheckWithState');
    ccheck({})
      .toPromise().
      then(resp => {
        const respDic = JSON.parse(resp)
        console.log(respDic)
        this.cserv.setRegion(respDic.region_code)
        this.findZoneByCC(respDic.country_code)
      }).catch(error => {
        console.log(error)
      })
  }

  findZoneByCC(cc: string) {
    this.db.collection('zones', ref => ref.where('cc','==',cc)).valueChanges()
      .subscribe((zones: any[]) => {
        // console.log(zones[0])
        if (zones.length > 0) {
          this.cserv.newSetCountry(zones[0].zone)
          if (zones[0].lang) {
            this._translate.use(zones[0].lang)
          }
        } else {
          this.cserv.openerFlip();
        }
      })
  }

  settingitupnew(cc: string) {
    this.db.collection('zones', ref => ref.where('zone','==',cc)).valueChanges()
      .subscribe((zones: any[]) => {
        this.cserv.newSetCountry(zones[0].zone)
        if (zones[0].lang) {
          this._translate.use(zones[0].lang)
        }
      })
  }

  newLang(lang: string) {
    console.log("settingnewlang");
    this.settingitupnew(lang);
  }

  // opencountrypicker() {
  //   console.log("should open");
  //   this.countrypicker = true;
  // }
  // closecountrypicker() {
  //   this.countrypicker = false;
  // }


}
