import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Country, Region } from '../../core/country';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-zone',
  templateUrl: './new-zone.component.html',
  styleUrls: ['./new-zone.component.css']
})
export class NewZoneComponent implements OnInit {
  zoneForm: FormGroup;
  regions$: Observable<any[]>
  zones$: Observable<Country[]>

  constructor(
    private fb: FormBuilder,
    private db: AngularFirestore,
    private router: Router,
  ) {
    this.regions$ = db.collection('regions').valueChanges();
    this.zones$ = db.collection<Country>('zones').valueChanges();
  }

  ngOnInit() {
    this.zoneForm = this.fb.group({
      zone:               ['', Validators.required],
      name:               '',
      threeDestination:  false,
      sixty:             false,
      us:                false,
      singleDestination: false,
      noDestination:     false,
      amDestination:     false,
      fullTravelClubInclude: false,
      intTravelClubInclude: false,
      titanium:          false,
      annualTravelClub:  false,
      tendays:           false,
      thirtydays:        false,
      cn:                '',
      restaurantDotCom: false,
      shopAndDinning: false,
      travelAgentPro: false,
      fiftyTravel: false,
      taReferral: false,
      spainIncentive: false,
    })
  }

  setLike(region: string) {
    var regionRef = this.db.doc<Region>(`regions/${region}`);
    regionRef.valueChanges().subscribe((reg) => {
      this.zoneForm.get('threeDestination').setValue(reg.threeDestination)
      this.zoneForm.get('sixty').setValue(reg.sixty)
      this.zoneForm.get('us').setValue(reg.us)
      this.zoneForm.get('singleDestination').setValue(reg.singleDestination)
      this.zoneForm.get('noDestination').setValue(reg.noDestination)
      this.zoneForm.get('amDestination').setValue(reg.amDestination)
      this.zoneForm.get('fullTravelClubInclude').setValue(reg.fullTravelClubInclude)
      this.zoneForm.get('intTravelClubInclude').setValue(reg.intTravelClubInclude)
      this.zoneForm.get('titanium').setValue(reg.titanium)
      this.zoneForm.get('annualTravelClub').setValue(reg.annualTravelClub)
      this.zoneForm.get('tendays').setValue(reg.tendays)
      this.zoneForm.get('thirtydays').setValue(reg.thirtydays)

      this.zoneForm.get('restaurantDotCom').setValue(reg.restaurantDotCom)
      this.zoneForm.get('shopAndDinning').setValue(reg.shopAndDinning)
      this.zoneForm.get('travelAgentPro').setValue(reg.travelAgentPro)
      this.zoneForm.get('fiftyTravel').setValue(reg.fiftyTravel)
      this.zoneForm.get('taReferral').setValue(reg.taReferral)
      this.zoneForm.get('spainIncentive').setValue(reg.spainIncentive)
    });
  }

  submitHandler() {
    const zne = this.zoneForm.get('zone').value
    if (zne) {
        const zoneRef: AngularFirestoreDocument = this.db.doc(`zones/${zne}`)
        const data: Country = {
        zone: zne,
        name: this.zoneForm.get('name').value,
        threeDestination: this.zoneForm.get('threeDestination').value,
        sixty: this.zoneForm.get('sixty').value,
        us: this.zoneForm.get('us').value,
        singleDestination: this.zoneForm.get('singleDestination').value,
        noDestination: this.zoneForm.get('noDestination').value,
        amDestination: this.zoneForm.get('amDestination').value,
        fullTravelClubInclude: this.zoneForm.get('fullTravelClubInclude').value,
        intTravelClubInclude: this.zoneForm.get('intTravelClubInclude').value,
        titanium: this.zoneForm.get('titanium').value,
        annualTravelClub: this.zoneForm.get('annualTravelClub').value,
        tendays: this.zoneForm.get('tendays').value,
        thirtydays: this.zoneForm.get('thirtydays').value,
        cn: this.zoneForm.get('cn').value,

        restaurantDotCom: this.zoneForm.get('restaurantDotCom').value,
        shopAndDinning: this.zoneForm.get('shopAndDinning').value,
        travelAgentPro: this.zoneForm.get('travelAgentPro').value,
        fiftyTravel: this.zoneForm.get('fiftyTravel').value,
        taReferral: this.zoneForm.get('taReferral').value,
        spainIncentive: this.zoneForm.get('spainIncentive').value,
      }
      zoneRef.set(data).then(
        async () => {
          const shortRef: AngularFirestoreDocument = this.db.doc(`short_zones/${zne}`)
          await shortRef.set({name: data.name, zone: zne})
          this.clearForm()
        },
        (error) => console.log(error),
      );
    } else {
      console.log('No zone!')
    }

  }

  private clearForm() {
    this.zoneForm.get('zone').setValue('')
    this.zoneForm.get('name').setValue('')
    this.zoneForm.get('threeDestination').setValue(false)
    this.zoneForm.get('sixty').setValue(false)
    this.zoneForm.get('us').setValue(false)
    this.zoneForm.get('singleDestination').setValue(false)
    this.zoneForm.get('noDestination').setValue(false)
    this.zoneForm.get('amDestination').setValue(false)
    this.zoneForm.get('fullTravelClubInclude').setValue(false)
    this.zoneForm.get('intTravelClubInclude').setValue(false)
    this.zoneForm.get('titanium').setValue(false)
    this.zoneForm.get('annualTravelClub').setValue(false)
    this.zoneForm.get('tendays').setValue(false)
    this.zoneForm.get('thirtydays').setValue(false)
    this.zoneForm.get('cn').setValue('')
    this.zoneForm.get('spainIncentive').setValue(false)
  }

}
