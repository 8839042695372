import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  userForm: FormGroup;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private fb: FormBuilder,
  ) {
    this.userForm = this.fb.group({
      'email': ['', [Validators.required,Validators.email]],
      'password': ['',],
    });
  }

  ngOnInit() {
  }

  emailLogin() {
    console.log("Signing into: ",this.userForm.value['email']);
    return this.afAuth.auth
      .signInWithEmailAndPassword(this.userForm.value['email'], this.userForm.value['password'])
      .then(credential => {
        this.router.navigateByUrl('/admin/dash');
      })
      .catch(error => console.log(error));
  }

  logout() {
    this.afAuth.auth.signOut();
  }

}
