import { Component, OnInit, OnDestroy } from '@angular/core';
import { WorldtourService } from '../../core/worldtour.service';
import { IdService } from '../../core/id.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '../../translate';
import * as $ from 'jquery';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-new-booking',
  templateUrl: './new-booking.component.html',
  styleUrls: ['./new-booking.component.css']
})
export class NewBookingComponent implements OnInit {
  id: any;
  loader: boolean = true;
  selectedBooking: any = null;
  seqID: string = '';

  constructor(
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private functions: AngularFireFunctions,
    private wts: WorldtourService,
    private auth: IdService,
    private router: Router
  ) {
    this.route.queryParams.subscribe(async params => {
      await new Promise(async (resolve, reject) => {
        if (params['id'] && params['hash']) {
          this.id = params['id'];
          if (!this.auth.verify(this.id,params['hash'])) {
            console.log('Failed Verification')
            window.location.replace("https://backoffice.travorium.com/travorium/member_new2/welcome.asp");
          }
          console.log('Pass Verification');
          await this.wts.loadTours().then((status) => {
            if (status) {
              this.loader = false;
            } else {
              reject({status: 'failed', message: 'Failed to load tours'});
            }
          })
          resolve(true);
        } else if (auth.salted) {
          console.log('Previously Salted');
          this.id = this.auth.id
          resolve(true);
        } else {
          window.location.replace("https://backoffice.travorium.com/travorium/member_new2/welcome.asp");
          reject(false);
        };
      }).then((status) => {
        this.loader = false;
        this.route.params.subscribe((prams) => {
          // console.log(wts.booked);
          if (prams['seqID']) {
            this.seqID = prams['seqID'];
            this.selectBooking(prams['seqID']);
          } else {

            console.log('no seqID');
          }
        })
      }).catch((error) => {
        console.log(error);
      })

    });
  }

  ngOnInit() {
  }

  selectBooking(seqID: string) {
    this.selectedBooking = this.wts.booked.find(booking => {
      return (booking.SaleData.Seq == seqID)
    })
    this.createBooking();
  }

  createBooking() {
    let bookingRef = this.db.doc(`/wt_travelers/${this.seqID}`)
    const doc = bookingRef.get().toPromise().then((snapshot) => {
      if (snapshot.exists) {
        this.router.navigate(['/my-tours/',this.seqID]);
      } else {
        let booking = {
          seqID: this.selectedBooking.SaleData.Seq,
          cs_id: this.id,
          tour_info: this.selectedBooking.SaleData.tour_info,
          trip_info: this.selectedBooking.SaleData.trip_info,
          traveler_info: [{name: this.selectedBooking.SaleData.Name, dob: '', type: 'adult'}],
          adults: this.selectedBooking.SaleData.Adults,
          children: this.selectedBooking.SaleData.Children,
          amount: this.selectedBooking.SaleData.Amount,
          extra_total: this.selectedBooking.SaleData.ExtraTotal,
          points: this.selectedBooking.SaleData.Points,
          trans_date: this.selectedBooking.SaleData.TransDate
        }
        booking.traveler_info.push(...Array(booking.adults-1).fill({
          name: '',
          dob: '',
          type: 'adult'
        }))
        booking.traveler_info.push(...Array(booking.children).fill({
          name: '',
          dob: '',
          type: 'child'
        }))
        bookingRef.set(booking).then(result =>{
          this.router.navigate(['/my-tours/',this.seqID]);
        }).catch(error => {
          console.log(error)
        })
      }
    }).catch((error) => {
      console.log(error);
    })
  }

}
