import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FreeTrip } from '../../core/freetrip';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of, from, BehaviorSubject } from 'rxjs';
import { CountryService } from '../../core/country.service';
import { TranslatePipe, TranslateService } from '../../translate';
import * as $ from 'jquery';

@Component({
  selector: 'app-free-vacation-incentive',
  templateUrl: './free-vacation-incentive.component.html',
  styleUrls: ['./free-vacation-incentive.component.css']
})
export class FreeVacationIncentiveComponent implements OnInit {
  zone: string;
  spons: string;
  ustab: boolean;
  mxtab: boolean;
  othertab: boolean;
  freetrips;
  displaytrip: FreeTrip;
  _ustrips: Observable<any[]>;
  _mxtrips: Observable<any[]>;
  _thailandtrips: Observable<any[]>;
  _spaintrips: Observable<any[]>;
  _indotrips: Observable<any[]>;


  constructor(
    private trans: TranslateService,
    private db: AngularFirestore,
    private cserv: CountryService,
    private route: ActivatedRoute,
  ) {
    this.freetrips = db.collection<FreeTrip>('free_trip').valueChanges();
    this._ustrips = db.collection<FreeTrip>('free_trip', ref => ref.where('region', '==', 'US')).valueChanges();
    this._mxtrips = db.collection<FreeTrip>('free_trip', ref => ref.where('region', '==', 'Mexico')).valueChanges();
    this._thailandtrips = db.collection<FreeTrip>('free_trip', ref => ref.where('region', '==', 'Thailand')).valueChanges();
    this._spaintrips = db.collection<FreeTrip>('free_trip', ref => ref.where('region', '==', 'Spain')).valueChanges();
    this._indotrips = db.collection<FreeTrip>('free_trip', ref => ref.where('region', '==', 'Indonesia')).valueChanges();

    this.route.params.subscribe((routeParams) => {
      this.zone = routeParams['zone']
      this.spons = routeParams['sponsor']
      const spons = routeParams['sponsor']
   // cserv.checkCountry(this.zone, spons);
    });
  }

  ngOnInit() {
    this.ustab = true;
    $(document).ready( function($){
      let media = <HTMLVideoElement> document.getElementById("mainvideo");
      //const playPromise = media.play();
      //  if (playPromise !== null){
      //    playPromise.catch(() => { media.play(); })
      //  }

      $(".btn-pref .btn").click(function () {
          $(".btn-pref .btn").removeClass("btn-primary").addClass("btn-default");
          $(this).removeClass("btn-default").addClass("btn-primary");
      });

      if (media) {
          media.muted = true;
      }
    });
  }

  displayATrip(passedcity) {
    this.displaytrip=passedcity
  }

  changetab(tab) {
    if(tab == "1") {
      this.ustab = true;
      this.mxtab = false;
      this.othertab = false;
    } else if (tab == "2") {
      this.ustab = false;
      this.mxtab = true;
      this.othertab = false;
    } else {
      this.ustab = false;
      this.mxtab = false;
      this.othertab = true;
    }
  }

}
