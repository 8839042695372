import { Component, OnInit } from '@angular/core';
import { CountryService } from '../../core/country.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TranslatePipe } from '../../translate';
import { SponsorService } from '../../core/sponsor.service';

@Component({
  selector: 'app-compplan',
  templateUrl: './compplan.component.html',
  styleUrls: ['./compplan.component.css']
})
export class CompplanComponent implements OnInit {
  zone;
  spons;

  constructor(
    private cserv: CountryService,
    private route: ActivatedRoute,
    private sserv: SponsorService,
  ) {
    this.route.params.subscribe((routeParams) => {
      this.zone = routeParams['zone']
      this.spons = routeParams['sponsor']

     
  
    });

  }

  ngOnInit() {
  }
  goBack() {
    window.history.back();
  }

}
