import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '../../translate';
import * as $ from 'jquery';
import { CountryService } from '../../core/country.service';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { SponsorService } from '../../core/sponsor.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {
  zone: string;
  sponsor: string;
  sID: string;
  // zones$: Observable<any[]>;


  constructor(
    private cserv: CountryService,
    private route: ActivatedRoute,
    private router: Router,
    private db: AngularFirestore,
    private sserv: SponsorService,
  ) {
    this.route.params.subscribe((routeParams) => {
      this.sponsor = routeParams['sponsor']
      if (routeParams['zone']) {
        cserv.checkCountry(routeParams['zone'], this.sponsor);
      } else {}
      const zonesRef = this.db.collection('zones')
      // this.grabCountry()
      this.zone = routeParams['zone']
      // this.zones$ = zonesRef.valueChanges();
      // this.zones$.subscribe((zones) => {
      // })

    });
   }

  ngOnInit() {

  }
  bringdownregionform() {
    $('#regionform').toggle("slow");
  }
  gotonewsponsor() {
    window.location.href = '/'+this.sID;
  }
  gotonewcountry(newcountry) {
    window.location.href = '/'+newcountry+'/'+this.sponsor;
  }


}
