import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Country, Region } from '../../core/country';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-edit-zone',
  templateUrl: './edit-zone.component.html',
  styleUrls: ['./edit-zone.component.css']
})
export class EditZoneComponent implements OnInit {
  zone: string;
  zoneForm: FormGroup;
  zoneRef: AngularFirestoreDocument<Country>

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private db: AngularFirestore,
    private router: Router,
  ) {
    this.route.params.subscribe(routeParams => {
          this.zone = routeParams['zone'];
      });

      this.zoneRef = this.db.doc<Country>(`zones/${this.zone}`)
      const zoneDoc = this.zoneRef.valueChanges().subscribe(zne => {
        if (zne) {
          this.zoneForm.get('name').setValue(zne.name)
          this.zoneForm.get('threeDestination').setValue(zne.threeDestination)
          this.zoneForm.get('sixty').setValue(zne.sixty)
          this.zoneForm.get('us').setValue(zne.us)
          this.zoneForm.get('singleDestination').setValue(zne.singleDestination)
          this.zoneForm.get('noDestination').setValue(zne.noDestination)
          this.zoneForm.get('amDestination').setValue(zne.amDestination)
          this.zoneForm.get('fullTravelClubInclude').setValue(zne.fullTravelClubInclude)
          this.zoneForm.get('intTravelClubInclude').setValue(zne.intTravelClubInclude)
          this.zoneForm.get('titanium').setValue(zne.titanium)
          this.zoneForm.get('annualTravelClub').setValue(zne.annualTravelClub)
          this.zoneForm.get('tendays').setValue(zne.tendays)
          this.zoneForm.get('thirtydays').setValue(zne.thirtydays)
          this.zoneForm.get('cn').setValue(zne.cn)

          this.zoneForm.get('restaurantDotCom').setValue(zne.restaurantDotCom)
          this.zoneForm.get('shopAndDinning').setValue(zne.shopAndDinning)
          this.zoneForm.get('travelAgentPro').setValue(zne.travelAgentPro)
          this.zoneForm.get('fiftyTravel').setValue(zne.fiftyTravel)
          this.zoneForm.get('taReferral').setValue(zne.taReferral)
          this.zoneForm.get('spainIncentive').setValue(zne.spainIncentive)
          this.zoneForm.get('currency').setValue(zne.currency ? zne.currency : 'USD')

        } else {
          this.router.navigateByUrl('admin/zones')
        }
      });

      this.zoneForm = this.fb.group({
        name:               '',
        threeDestination:  false,
        sixty:             false,
        us:                false,
        singleDestination: false,
        noDestination:     false,
        amDestination:     false,
        fullTravelClubInclude: false,
        intTravelClubInclude: false,
        titanium:          false,
        annualTravelClub:  false,
        tendays:           false,
        thirtydays:        false,
        cn:                '',
        restaurantDotCom: false,
        shopAndDinning: false,
        travelAgentPro: false,
        fiftyTravel: false,
        taReferral: false,
        spainIncentive: false,
        currency: 'USD',
      })
   }

  ngOnInit() {
  }

  submitHandler() {
    const data: Country = {
      zone: this.zone,
      name: this.zoneForm.get('name').value,
      threeDestination: this.zoneForm.get('threeDestination').value || false,
      sixty: this.zoneForm.get('sixty').value || false,
      us: this.zoneForm.get('us').value || false,
      singleDestination: this.zoneForm.get('singleDestination').value || false,
      noDestination: this.zoneForm.get('noDestination').value || false,
      amDestination: this.zoneForm.get('amDestination').value || false,
      fullTravelClubInclude: this.zoneForm.get('fullTravelClubInclude').value || false,
      intTravelClubInclude: this.zoneForm.get('intTravelClubInclude').value || false,
      titanium: this.zoneForm.get('titanium').value || false,
      annualTravelClub: this.zoneForm.get('annualTravelClub').value || false,
      tendays: this.zoneForm.get('tendays').value || false,
      thirtydays: this.zoneForm.get('thirtydays').value || false,
      cn: this.zoneForm.get('cn').value || null,
      restaurantDotCom: this.zoneForm.get('restaurantDotCom').value || false,
      shopAndDinning: this.zoneForm.get('shopAndDinning').value || false,
      travelAgentPro: this.zoneForm.get('travelAgentPro').value || false,
      fiftyTravel: this.zoneForm.get('fiftyTravel').value || false,
      taReferral: this.zoneForm.get('taReferral').value || false,
      spainIncentive: this.zoneForm.get('spainIncentive').value || false,
      currency: this.zoneForm.get('currency').value || 'USD',
    }
    this.zoneRef.set(data, {merge: true}).then(
      async () => {
        const shortRef: AngularFirestoreDocument = this.db.doc(`short_zones/${data.zone}`)
        let shortData = {
          name: data.name,
          zone: data.zone,
        }
        if (data.cn) shortData['cn'] = data.cn
        if (data.cc) shortData['cc'] = data.cc
        if (data.currency) shortData['currency'] = data.currency
        await shortRef.update(shortData)
        this.router.navigateByUrl('/admin/zones')
      },
      (error) => console.log(error),
    );
  }

}
