import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './ui/home/home.component';
import { CompplanComponent } from './ui/compplan/compplan.component';
import { WetripComponent } from './ui/wetrip/wetrip.component';
import { LaunchComponent } from './ui/launch/launch.component';
import { WorldtoursComponent } from './ui/worldtours/worldtours.component';
import { TourComponent } from './ui/tour/tour.component';
import { TripComponent } from './ui/trip/trip.component';
import { CheckoutComponent } from './ui/checkout/checkout.component';
import { BookedComponent } from './ui/booked/booked.component';
import { NewBookingComponent } from './ui/new-booking/new-booking.component';

import { EnrollComponent } from './ui/enroll/enroll.component';
import { PartnershipComponent } from './ui/partnership/partnership.component';

import { DreamcationComponent } from './ui/dreamcation/dreamcation.component';
import { ViewZonesComponent } from './admin/view-zones/view-zones.component';
import { EditZoneComponent } from './admin/edit-zone/edit-zone.component';
import { NewZoneComponent } from './admin/new-zone/new-zone.component';
import { DashComponent } from './admin/dash/dash.component';
import { LoginComponent } from './admin/login/login.component';
import { WorldToursComponent } from './admin/world-tours/world-tours.component';
import { TourEditComponent } from './admin/tour/tour.component';
import { TripEditComponent } from './admin/trip/trip.component';
import { BookingsComponent } from './admin/bookings/bookings.component';
import { ImportBookingsComponent } from './admin/import-bookings/import-bookings.component';
import { SpecialImportComponent } from './admin/special-import/special-import.component';
import { EditBookingComponent } from './admin/edit-booking/edit-booking.component';
import { AuthGuard } from './admin/auth.guard'

import { FreeVacationIncentiveComponent } from './ui/free-vacation-incentive/free-vacation-incentive.component';
import { SponsorGuard } from './core/sponsor.guard';
import { SponsorZoneGuard } from './core/sponsor-zone.guard';



const routes: Routes = [
  { path: 'admin/zones', component: ViewZonesComponent, canActivate: [AuthGuard] },
  { path: 'admin/edit/:zone', component: EditZoneComponent, canActivate: [AuthGuard] },
  { path: 'admin/new-zone', component: NewZoneComponent, canActivate: [AuthGuard]},
  { path: 'admin/tour/:tour_id', component: TourEditComponent, canActivate: [AuthGuard]},
  { path: 'admin/tour/:tour_id/:trip_id', component: TripEditComponent, canActivate: [AuthGuard]},
  { path: 'admin/dash', component: DashComponent, canActivate: [AuthGuard]},
  { path: 'admin/world-tours', component: WorldToursComponent, canActivate: [AuthGuard]},
  { path: 'admin/bookings', component: BookingsComponent, canActivate: [AuthGuard]},
  { path: 'admin/bookings/import', component: ImportBookingsComponent, canActivate: [AuthGuard]},
  { path: 'admin/bookings/import/special', component: SpecialImportComponent, canActivate: [AuthGuard]},
  { path: 'admin/bookings/edit/:seqID', component: EditBookingComponent, canActivate: [AuthGuard]},
  { path: 'admin/login', component: LoginComponent},


  // NEW routing
  { path: '', component: HomeComponent },
  { path: 'launch', component: LaunchComponent },
  { path: 'launch/:sponsor', component: LaunchComponent },

  { path: 'enroll/:sponsor', component: EnrollComponent },
  { path: 'enroll/:sponsor/:travellevel/:travelpack', component: EnrollComponent },
  { path: 'enroll/:sponsor/:travellevel/:travelpack/:checkout', component: EnrollComponent },
  { path: 'compplan', component: CompplanComponent },
  
  { path: 'worldtours', component: WorldtoursComponent },
  { path: 'tour/:tour-id', component: TourComponent},
  { path: 'tour/:tour-id/:trip-id', component: TripComponent},
  { path: 'checkout/:tour-id/:trip-id/:room-id', component: CheckoutComponent},

  { path: 'my-tours', component: BookedComponent },
  { path: 'my-tours/:seqID', component: BookedComponent},
  { path: 'new-booking/:seqID', component: NewBookingComponent},

  { path: 'partnership', component: PartnershipComponent },

  { path: 'free-vacation-incentive', component: FreeVacationIncentiveComponent },
  { path: 'free-vacation-incentive/:sponsor', component: FreeVacationIncentiveComponent },

  { path: 'dreamcation', component: DreamcationComponent },
  { path: 'dreamcation/:sponsor', component: DreamcationComponent },
  { path: 'dreamcation/:sponsor/:hash/:active/:points/:eligible_membership_level/:email/:numberleft', component: DreamcationComponent },
  { path: ':sponsor', component: HomeComponent },


  // OLD routing

  { path: ':zone/dreamcation', component: DreamcationComponent },
  { path: ':zone/dreamcation/:sponsor', component: DreamcationComponent },
  { path: ':zone/dreamcation/:sponsor/:active/:points/:eligible_membership_level/:email/:numberleft', component: DreamcationComponent },
  { path: ':zone/:sponsor', component: HomeComponent, canActivate: [SponsorZoneGuard] },

  { path: ':zone/enroll/:sponsor', component: EnrollComponent },

]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
