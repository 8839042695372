import { Component, OnInit } from '@angular/core';
import { TranslateService, TranslatePipe } from '../../translate';

@Component({
  selector: 'select-lang',
  templateUrl: './select-lang.component.html',
  styleUrls: ['./select-lang.component.css']
})
export class SelectLangComponent implements OnInit {
  public supportedLangs: any[] = [{ display: 'English', value: 'en' },
   { display: 'Español', value: 'es' }, { display: 'Français', value: 'fr'}]

  constructor(
    private _translate: TranslateService,
  ) { }

  ngOnInit() {
    this.supportedLangs = [
        { display: 'English', value: 'en' },
        { display: 'Español', value: 'es' },
        { display: 'Français', value: 'fr'}
      ];
  }

  isCurrentLang(lang: string) {
    if (this._translate.currentLang === undefined) {
      return 'en';
    } else {
    return lang === this._translate.currentLang;
    }

  }

  selectLang(lang: string) {
    // set default;
    this._translate.use(lang);
  }
}
