import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-trip',
  templateUrl: './trip.component.html',
  styleUrls: ['./trip.component.css']
})
export class TripEditComponent implements OnInit {
  tour_id: string;
  tour;
  trip_id: string;
  trip;
  private;
  saved: boolean;
  isthisnew: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private message: MessageService
  ) {
    this.route.params.subscribe(params => {
      console.log('looking at params')
      if (params['tour_id']) {
        console.log('found a param')
        if (params['trip_id'] != 'new') {
          console.log('not a new one')
          this.isthisnew = false;
          this.tour_id = params['tour_id'];
          this.trip_id = params['trip_id'];
          this.setTrip(this.tour_id,this.trip_id);
        } else {
          console.log('going to try to set a new trip')
          this.tour_id = params['tour_id'];
          this.newTrip(this.tour_id);
        }
      }
    })
  }

  ngOnInit() {

  }


  setTrip(tour_id: string, trip_id: string) {
    let tourRef = this.db.doc<any>(`world_tours/${tour_id}`);
    tourRef.valueChanges().subscribe((tour) => {
      if (tour) {
        this.tour = tour;
        console.log(tour);
      } else {
        console.log("can't find tour: ${tour_id} ")
      }
    })
    let tripRef = this.db.doc<any>(`world_tours/${tour_id}/trips/${trip_id}`)
    tripRef.valueChanges().subscribe((trip) => {
      if (trip) {
        this.trip = trip;
        console.log(trip);
      }
    })
    let privateRef = this.db.doc<any>(`world_tours/${tour_id}/private/${trip_id}`)
    privateRef.valueChanges().subscribe((myprivate) => {
      if (myprivate) {
        this.private = myprivate;
      } else {
        this.private = {
          rooms: [],
        }
        this.private.rooms.push({
          t_rate: 0
        })
        this.private.rooms.push({
          t_rate: 0
        })
        this.private.rooms.push({
          t_rate: 0
        })
        this.private.rooms.push({
          t_rate: 0
        })
        this.private.rooms.push({
          t_rate: 0
        })
        this.private.rooms.push({
          t_rate: 0
        })
        this.private.rooms.push({
          t_rate: 0
        })
        console.log('no private exists')
      }
    })
  }


  localDateString(d: Date): string {
    const YYYY = String(d.getFullYear());
    const MM = d.getMonth()+1>= 10 ? String(d.getMonth()+1): '0'+String(d.getMonth()+1);
    const DD = d.getDate()>= 10 ? String(d.getDate()): '0'+String(d.getDate());
    let hh = d.getHours() >= 10 ? String(d.getHours()): '0'+String(d.getHours());
    let mm = d.getMinutes()>= 10 ? String(d.getMinutes()): '0'+String(d.getMinutes());
    let ss = d.getSeconds()>= 10 ? String(d.getSeconds()): '0'+String(d.getSeconds());
    return YYYY+'-'+MM+'-'+DD+'T'+hh+':'+mm+':'+ss;
  }

  newTrip(tour_id: string) {
    let tourRef = this.db.doc<any>(`world_tours/${tour_id}`);
    tourRef.valueChanges().subscribe((tour) => {
      if (tour) {
        this.tour = tour;
        console.log(tour);
      } else {
        console.log(`can't find tour: ${tour_id}`)
      }
    })
    console.log('Creating a new Trip');
    this.isthisnew = true;
    this.trip = {
      trip_id: '',
      trip_title: '',
      start_date: null,
      end_date: null,
      finalpaymentdue: null,
      uid: null,
      nights: '',
      days: '',
      disclaimers: '',
      month: '',
      rooms: [],
    }
  }

  slugify(text: string) {
    return text.toString()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^\w-]+/g, '')
      .replace(/--+/g, '-')
  }

  saveTrip() {
    //if(this.tour.rooms.length === 0) {
    //} else {
    //  this.tour.rooms.forEach(function (singleroom) {
    //    singleroom
    //  });
    //}
    let data = this.trip;

    let privatedata = {...data};

    let _rooms = [];
    for(let i=0; i<data.rooms.length; i++){
      let room = {
        ...data.rooms[i],
        t_rate: this.private.rooms[i].t_rate
      };
      _rooms.push(room);
    }
    console.log(_rooms);
    privatedata.rooms = _rooms;
    privatedata.tour_id = this.tour.uid;

    data.trip_id = this.slugify(data.trip_title);



    if (!!this.isthisnew) {

      return this.db.doc(`world_tours/${this.tour.tour_id}/trips/${data.trip_id}`).set(data, {merge: true})
      .then(() => {
        console.log('New trip Set!');
        this.saved = true;

        alert("Saved a brand new Trip!");

        //this.message.add({severity:'success', summary: 'Success', detail: 'tour Saved'});
      }).catch(err => {console.log(err)});

    } else {

      return this.db.doc(`world_tours/${this.tour.tour_id}/trips/${data.trip_id}`).update(data)
      .then(() => {
        console.log('UPDATED a Trip!');
        this.saved = true;

        alert("Saved Existing Trip!");
          this.db.doc(`world_tours/${this.tour.tour_id}/private/${data.trip_id}`).set(privatedata, {merge: true})
          .then(() => {
            console.log('Saved Private Values');

            alert("Saved Private Values!");

          }).catch(err => {console.log(err)});
      }).catch(err => {console.log(err)});

    }
  }




  addRoom() {
    this.private.rooms.push({
      t_rate: 0
    })
    this.trip.rooms.push({
      name: '',
      id: '',
      tripid: '',
      howmanyrooms: 0,
      regularprice: null,
      lowestprice_p: null,
      lowestprice_t: null,
      adults: 2,
      child: 0,
      adultsincluded: null,
      maxadults: null,
      maxchild: 0,
      maxpeople: null,
      costofchildren: null,
      costofadult: null,
      depositcost: null,
      amounttopay: null,
      addonscost: null,
      childage: 12,
      nights: 0,
      active: true,
      depositpaid: true,
      pod: false,
      full: false,
      extrastring: '',
      numberofrooms: null,
    })
  }
  addvarient(room) {
    if( room.variants ) {
      room.variants.push("");
    } else {
      room.variants = [];
      room.variants.push("");
    }

  }

  removevarient(i, room) {
    room.variants.splice(i, 1);

  }



  removeRoom(i) {
    this.trip.rooms.splice(i, 1);
  }



}
