import { Component, OnInit } from '@angular/core';
import { CountryService } from '../../core/country.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '../../translate';
import * as $ from 'jquery';
import * as CryptoJS from 'crypto-js';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { NouisliderModule } from 'ng2-nouislider';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-wetrip',
  templateUrl: './wetrip.component.html',
  styleUrls: ['./wetrip.component.css']
})
export class WetripComponent implements OnInit {
  zone: string;
  sponsor: string;
  sID: string;
  selectproduct;
  hash;
  passedafterhash;
  passedlocations;
  id;
  seqid;
  upcomingpromo = true;
  loader: boolean;
  trips: any[];
  currentTrips: any[];
  pastTrips: any[];
  autoauth;
  checkout;
  resortoverview;
  selectedroom;
  selectedresort;
  currentlistofdiscistrue;
  currentlistofdisc;
  travelerinfo;
  travelerinfotoedit;
  rank;
  dateoptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  dateoptionsSmaller = { year: 'numeric', month: 'long', day: 'numeric' };
  toomanypeople;
  livepts: number;
  membership: string;
  private salt = "6481a638-9347-4211-92d7-f98137d260cd";
  booklink = "https://backoffice.travorium.com/travorium/enroll_new4/cc_auth_WETRIP.asp?id=";


  //WeTripID|Deposit or Balance|Amount|PointsUsed|RoomType|Adults|Children|ExtraTotal


  

  constructor(		
    private cserv: CountryService,
		private route: ActivatedRoute,
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private functions: AngularFireFunctions,
    private router: Router,) { 
      this.route.queryParams.subscribe(params => {
        this.id = params['id'];
        this.hash = params['hash'];
        if (this.hash == CryptoJS.MD5(this.id+"-"+this.salt)) {
        //if (this.hash) {

          this.loadTours();
          if (params['seqid']) {
            this.seqid = params['seqid'];
            
          }
          if (params['showpromo']) {
            this.upcomingpromo = true;
            
          }

        } else {
          window.location.replace("https://travorium.com");
        };
        

        
      });
      
    }
    

    

  ngOnInit() {
    this.selectproduct = true;
    this.loader = true;
  }

  roundthisnumber(x) {
    return Math.ceil(x);
  }

  gobacktohome() {
    this.selectproduct = true;
    this.resortoverview = false;
    this.checkout = false;
  }
  closeseqid() {
    this.travelerinfo = false;
  }
 
  setdepositpaid(location, roomgiven, value) {
    let triplocationobject = this.trips.find(trip => trip.uid == location);
    let roomlocationobject = triplocationobject.rooms.find(room => room.id == roomgiven);
    
    roomlocationobject.depositpaid = value;

  }
  setpodpaid(location, roomgiven, value, num, room) {
 
    let triplocationobject = this.trips.find(trip => trip.uid == location);
    let roomlocationobject = triplocationobject.rooms.find(room => room.id == roomgiven);
    
    roomlocationobject.pod = value;
    roomlocationobject.numberofrooms = roomlocationobject.howmanyrooms - num;
    //console.log(roomlocationobject.name + ' howmanyrooms=' + roomlocationobject.howmanyrooms + ' num=' +num);
    if( num >= roomlocationobject.howmanyrooms ) {
      //console.log('finding to set as full')
      roomlocationobject.full = true;
    }
    if(room.SaleData) {
      if(this.seqid == room.SaleData.Seq) {
        this.travelerinfotoedit = room.SaleData;
        this.travelerinfotoedit.location = location;
        this.travelerinfo = true;
      }
     
      console.log("adding SaleData to "+ roomlocationobject.name)
      roomlocationobject.SaleData = room.SaleData;
    }
  }
  sliderminimum(room) {
    let dollaramountinpoints = this.livepts / 2;
    let lowestprice = 0;
    if(this.rank == "T") {
      lowestprice = room.lowestprice_t;
    } else {
      lowestprice = room.lowestprice_p;
    }
    let thedifference = room.regularprice - dollaramountinpoints; 
    if( thedifference < lowestprice) {
      return lowestprice;
    } else {
      return thedifference;
    }
  }
  proceedtocheckout(room,disclaimers) {
    if (this.calcpeople(room)) {
      this.selectedroom = room;
      this.resortoverview = false;
      this.currentlistofdiscistrue = true;
      this.currentlistofdisc = disclaimers;
      console.log(this.currentlistofdisc);
      this.toomanypeople = false;
      this.checkout = true;
      room.addonscost = this.calcpeoplecost(room);
      window.scrollTo(0,0);
    } else {
      this.toomanypeople = true;
    }
  }
  proceedtoresort(resort) {
      this.selectedresort = resort;
      this.selectproduct = false;
      this.resortoverview = true;
      this.checkout = false;
      window.scrollTo(0,0);
  }
  proceedtoresortwithuid(tour_id) {
    console.log('findingthis '+tour_id);
    this.selectedresort = this.trips.find(i => i.tour_id == tour_id);
    //console.log(this.selectedresort)
    this.selectproduct = false;
    this.resortoverview = true;
    this.checkout = false;
    window.scrollTo(0,0);
}
  //LocationID|RoomName1,D or B|Amounttocharge|PointsUsed|Adults|Children|ExtraTotal
  bookbuilder(room,DorP) {
    let thepay = 0;
    let ptsused = 0;
    let adults = 1;
    let children = 0;
    let xtra = 0;
    console.log(room);
    if (DorP == 'D') {
      let fullhash = room.tripid+'|'+room.name+','+DorP+'|'+room.depositcost+'|'+ptsused+'|'+adults+'|'+children+'|'+xtra;
      this.bookem(fullhash);
    } else if (DorP == 'B') {
  //  thepay = room.amounttopay - room.depositcost + room.addonscost;
      thepay = room.amounttopay + room.addonscost;
      ptsused = this.calculatepointsused(room.regularprice, room.amounttopay);
      let fullhash = room.tripid+'|'+room.name+','+DorP+'|'+thepay+'|'+ptsused+'|'+room.adults+'|'+room.child+'|'+room.addonscost;
      this.bookem(fullhash);
    }
    
  }
  //https://backoffice.paid2save.com/Paid2SaveNetwork/enroll_new4/cc_auth_WETRIP.asp?id=10000&hash=
  //LocationID|RoomName1,D or B|Amount|PointsUsed|Adults|Children|ExtraTotal
  //.toString(CryptoJS.enc.Hex);
  //CryptoJS.enc.Utf8.parse(rawStr);  
  bookem(hash) {
    let fullhash = CryptoJS.enc.Utf8.parse(hash);
    var encrypted = CryptoJS.enc.Base64.stringify(fullhash);
    let fullink = this.booklink+this.id+'&hash='+encrypted;
    console.log(fullink);
    window.location.href = fullink;
  }
  calculateExistingPoints(x,y,z){
    let a = x-y;
    let pointvalue = a*2;
    return z-pointvalue;
  }
  calculatepointsused(x,y){
    let a = x-y;
    let pointvalue = a*2;
    return pointvalue;
  }
  calcadultscost(selectedroom) {
    if(selectedroom.adults > selectedroom.adultsincluded) {
    var firststep = selectedroom.adults - selectedroom.adultsincluded;
    var secondstep = firststep * selectedroom.costofadult * selectedroom.nights;
    return secondstep;
    } else {
      return 0;
    }
  }
  calcchildcost(selectedroom) {
    var total = 0;
    var secondstep = selectedroom.child * selectedroom.costofchildren * selectedroom.nights;
    total = total + secondstep;
    return total;
  }
  calcpeople(room) {
    let numofpeople = room.adults + room.child;
    if (numofpeople > room.maxpeople) {
      
      return false;
    } else {
      if (room.adults > room.maxadults || room.child > room.maxchild) {
        return false;
      } else {
        return true;
      }
      
    }
  }
  calcpeoplecost(room) {
    var childcost = 0;
    var adultcost = 0;
    var totalcost = 0;
    if (room.adults > room.adultsincluded) {
      let thenum = room.adults - room.adultsincluded;
      adultcost = thenum * room.costofadult;
    }
    if (room.child > 0) {
      let thenum = room.child;
      childcost = thenum * room.costofchildren;
    }
    totalcost = childcost + adultcost;
    totalcost *= room.nights;
    return totalcost;
  }
  calcFullPrice(roominquestion) {
    return roominquestion.amounttopay;
  }
  counter(i: number) {
      return new Array(i);
  }

  //test response
  testApi() {
    return new Promise((resolve) => {
      let sendit = '{"Locations":[{"LocationID":50,"Trips":[{"TripID":90,"Rooms":[{"RoomID":60,"Deposit":false,"Balance":false,"TotalPayments":1},{"RoomID":61,"Deposit":false,"Balance":false,"TotalPayments":1}]},{"TripID":91,"Rooms":[{"RoomID":63,"Deposit":false,"Balance":false,"TotalPayments":1},{"RoomID":64,"Deposit":false,"Balance":false,"TotalPayments":1}]},{"TripID":92,"Rooms":[{"RoomID":65,"Deposit":false,"Balance":false,"TotalPayments":1},{"RoomID":66,"Deposit":false,"Balance":false,"TotalPayments":1}]},{"TripID":93,"Rooms":[{"RoomID":67,"Deposit":false,"Balance":false,"TotalPayments":1},{"RoomID":68,"Deposit":false,"Balance":false,"TotalPayments":1}]},{"TripID":94,"Rooms":[{"RoomID":69,"Deposit":false,"Balance":false,"TotalPayments":1},{"RoomID":70,"Deposit":false,"Balance":false,"TotalPayments":1}]}]}],"MemLevel":"P","PointsAvail":"4308"}';
      resolve(sendit);
    });
  }

  loadTours() {
    let toursRef = this.db.collection<any>(`tours`, ref => {

      return ref;
    })
    toursRef.valueChanges().subscribe((tours) => {
      let now = new Date();
      if (tours) {
        this.trips = tours;
        this.trips = this.trips.map(tour => {
          tour.start_date_sortable = new Date(tour.start_date);
          tour.end_date_sortable = new Date(tour.end_date);
          tour.addonscost = 0;
          tour.rooms.forEach(room => {
            let finalpaymentdue = new Date(tour.finalpaymentdue);
            if(finalpaymentdue < now) {
              room.active = false;
            }
            room.amounttopay = room.regularprice;
            room.depositcost = room.lowestprice_p;
            room.numberofrooms = room.howmanyrooms;
          })
          return tour
        })
        this.trips = this.trips.sort((s1,s2) => {
          return s1.start_date_sortable - s2.start_date_sortable 
        })


        



        let callable = this.functions.httpsCallable('wtCheck');

        // Create an Observable and pass any data you want to the function
        const calltoCS = callable({ sponsorid: this.id });
    
        calltoCS.toPromise()
     
          .then(async (resp) => {
                this.loader = false;
                this.passedafterhash = JSON.parse(resp);
                
                if(this.passedafterhash.MemLevel == "P" || this.passedafterhash.MemLevel == "T") {
                  this.rank = this.passedafterhash.MemLevel;
                  this.livepts = this.passedafterhash.PointsAvail;
                  this.passedlocations = this.passedafterhash.Locations;
                }
                this.passedlocations.forEach(location => {
                  //console.log(location.LocationID);
                  location.Rooms.forEach(room => {
                    //this.setdepositpaid(location.LocationID, room.Name, room.Deposit);
                    //console.log(room.RoomID);
                    if(room) {
                      this.setpodpaid(location.LocationID, room.RoomID, room.Balance, room.TotalPayments, room);
                    } else {
                      console.log("tried and failed on "+room)
                    }
                   
                  }); 
                });
              })
              .catch((err) => {
              console.log(err);
              })
        


        
        this.currentTrips = this.trips.filter(tour => {
          if(tour.active == false) {

          } else {
            if(tour.start_date_sortable < now) {
              return false
            } else {
              return true
            }
          }
           
        })
        this.pastTrips = this.trips.filter(tour => {
          if(tour.active == false) {

          } else {
            if(tour.start_date_sortable < now) {
              return true
            } else {
              return false
            }
          }
        })
      }
    })
  }



}