import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { IdService } from './id.service';
import { take } from "rxjs/operators";
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class WorldtourService {
  destinations: any[];
  _seqid: any;
  booked: any[];
  private _bookedRooms: BehaviorSubject<any> = new BehaviorSubject([])

  constructor(
    private db: AngularFirestore,
    private functions: AngularFireFunctions,
    private auth: IdService
  ) { }

  get bookedRooms() {
    return this._bookedRooms.asObservable();
  }

  private async testApi() {
    console.log('waiting for fake api...')
    await new Promise(f => setTimeout(f, 350))
    console.log('done waiting');
    return new Promise<string>((resolve) => {
      let sendit: string = '\{"Locations":[\{"LocationID":50,"Trips":[\{"TripID":90,"Rooms":[\{"RoomID":60,"Deposit":false,"Balance":false,"TotalPayments":1\},\{"RoomID":61,"Deposit":false,"Balance":false,"TotalPayments":1\}]\},\{"TripID":91,"Rooms":[\{"RoomID":63,"Deposit":false,"Balance":false,"TotalPayments":1\},\{"RoomID":64,"Deposit":false,"Balance":false,"TotalPayments":1\}]\},\{"TripID":92,"Rooms":[\{"RoomID":65,"Deposit":false,"Balance":false,"TotalPayments":1\},\{"RoomID":66,"Deposit":false,"Balance":false,"TotalPayments":1\}]\},\{"TripID":93,"Rooms":[\{"RoomID":67,"Deposit":false,"Balance":false,"TotalPayments":1\},\{"RoomID":68,"Deposit":false,"Balance":false,"TotalPayments":1\}]\},\{"TripID":94,"Rooms":[\{"RoomID":69,"Deposit":false,"Balance":false,"TotalPayments":1\},\{"RoomID":70,"Deposit":false,"Balance":false,"TotalPayments":1\}]\}]\}],"MemLevel":"P","PointsAvail":"4308"\}';
      resolve(sendit);
    });
  }

  async loadTours(): Promise<any> {
    let destinationRef = this.db.collection<any>(`world_tours`, ref => {
      return ref;
    })
    return destinationRef.valueChanges().pipe(take(1)).toPromise().then((destinations) => {

      let now = new Date(+new Date());
      if (destinations) {
        this.booked= [];
        // this.destinations = destinations;
        this.destinations = destinations.map((destination) => {

          this.db.collection<any>(`world_tours/${destination.tour_id}/trips`).valueChanges().pipe(take(1)).toPromise().then(trips => {
            trips = trips.map(trip => {
              trip.start_date_sortable = new Date(trip.start_date);
              trip.end_date_sortable = new Date(trip.end_date);
              trip.finalpaymentdue_sortable = new Date(Date.parse(trip.finalpaymentdue) + 28800000);
              let finalpaymentdue = new Date(Date.parse(trip.finalpaymentdue) + 28800000);
              if (finalpaymentdue < now) {
                trip.active = false;
                trip.rooms.map(room => {
                  room.active = false;
                  // console.log("returning closed on room: "+trip.trip_id)
                  room.amounttopay = room.regularprice;
                  room.depositcost = room.lowestprice_p;
                  room.numberofrooms = room.howmanyrooms;
                  return room
                })
              } else {
                trip.rooms.map(room => {
                  room.amounttopay = room.regularprice;
                  room.depositcost = room.lowestprice_p;
                  room.numberofrooms = room.howmanyrooms;
                  return room
                })
              }
              trip.addonscost = 0;
              return trip
            })
            destination.trips = trips.sort((s1,s2) => {
              return s1.start_date_sortable - s2.start_date_sortable
            })
          })
          return destination
        })

        let callable = this.functions.httpsCallable('wtCheck');
        const calltoCS = callable({ sponsorid: this.auth.id });
        return calltoCS.toPromise()
        // this.testApi()
          .then(async (resp) => {
            // this.loader = false;
            let passedafterhash = JSON.parse(resp);
            if(passedafterhash.MemLevel == "P" || passedafterhash.MemLevel == "T") {
              this.auth.updateUser(passedafterhash.MemLevel,passedafterhash.PointsAvail);
            }
            let passedlocations = passedafterhash.Locations;
            await passedlocations.forEach(location => {
              location.Trips.forEach(trip => {
                if(trip) {
                  trip.Rooms.forEach(room => {

                    this.setpodpaid(location.LocationID, trip.TripID, room.RoomID, room.Balance, room.TotalPayments, room);
                  })
                } else {
                  console.log("tried and failed on "+trip)
                }
              });
            });
            return new Promise<any>((resolve) => {
              resolve(true);
            })
          })
          .catch((err) => {
          console.log(err);
          })
          // return new Promise<any>((resolve) => {
          //   resolve(true);
          // })
        // this.currentTrips = this.trips.filter(tour => {
        //   if(tour.active == false) {
        //
        //   } else {
        //     if(tour.start_date_sortable < now) {
        //       return false
        //     } else {
        //       return true
        //     }
        //   }
        //
        // })
        // this.pastTrips = this.trips.filter(tour => {
        //   if(tour.active == false) {
        //
        //   } else {
        //     if(tour.start_date_sortable < now) {
        //       return true
        //     } else {
        //       return false
        //     }
        //   }
        // })
      }
    })

  }

  async setpodpaid(location, tid, rid, value, num, room) {

    let tourlocationobject = this.destinations.find(destination => destination.uid == location && destination);

    let triplocationobject: any;
    let roomlocationobject: any;

    try {
      triplocationobject = tourlocationobject.trips.find(trip => trip.uid == tid)
      roomlocationobject = triplocationobject.rooms.find(room => room.id == rid);

    } catch (e) {
      if (e instanceof TypeError) {
        // console.log('keeping tossed error '+ e +' on ' + tourlocationobject.uid)
        await new Promise(f => setTimeout(f, 350))
        triplocationobject = tourlocationobject.trips.find(trip => trip.uid == tid)
        roomlocationobject = triplocationobject.rooms.find(room => room.id == rid);
      } else {
          throw e
      }
    }

      if (roomlocationobject) {
        roomlocationobject.pod = value;
        roomlocationobject.numberofrooms = roomlocationobject.howmanyrooms - num;
        if( num >= roomlocationobject.howmanyrooms ) {
          roomlocationobject.full = true;
        }
      }


      if(room.SaleData) {
        console.log(room.SaleData);
        let tour_info = {
          tour_id: tourlocationobject.tour_id,
          name: tourlocationobject.name,
          city: tourlocationobject.city,
          country: tourlocationobject.country,
          imgurl: tourlocationobject.imgurl,
        };
        let trip_info = {
          trip_id: triplocationobject.trip_id,
          trip_title: triplocationobject.trip_title,
          start_date: triplocationobject.start_date,
          end_date: triplocationobject.end_date,
        }

        room.SaleData.room_id = room.RoomID;
        room.SaleData.tour_info = tour_info;
        room.SaleData.trip_info = trip_info;
        roomlocationobject.SaleData = room.SaleData;
        this.booked.push(roomlocationobject);
        this.addBookedRoom(roomlocationobject);
      }
  }

  serveTour(tid: string) {
    return this.destinations.find((tour) => {
      return tour.tour_id == tid
    })
  }

  addBookedRoom(room) {
    let tmp_rooms = this._bookedRooms.getValue();
    tmp_rooms.push(room);
    this._bookedRooms.next(tmp_rooms);
  }

 roomAvailability(tour_id: string,trip_id: string,room_id: string) {
  let callable = this.functions.httpsCallable('wtCheck');
  const calltoCS = callable({ sponsorid: this.auth.id });
  return calltoCS.toPromise().then((resp) => {
    let parsed = JSON.parse(resp)
    let locations = parsed.Locations;
    let location = locations.find(location => location.LocationID == tour_id);
    let trip = location.Trips.find(trip => trip.TripID == trip_id);
    let room = trip.Rooms.find(room => room.RoomID == room_id);
    // console.log(room)
    let total_payments = room.TotalPayments;
    let afs_room = this.destinations.find(destination => {
      return destination.uid == tour_id
    }).trips.find(trip => {
      return trip.uid == trip_id
    }).rooms.find(room => {
      return room.id == room_id
    })
    return total_payments < afs_room.howmanyrooms
  }).catch(err => {
    console.log(err);
    return false
  })
 }
}
