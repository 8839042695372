import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CountryService } from '../../core/country.service';
import { TranslatePipe, TranslateService } from '../../translate';
import * as $ from 'jquery';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  zone: string;
  spons: string;


  constructor(
    private trans: TranslateService,
    private cserv: CountryService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe((routeParams) => {
      this.zone = routeParams['zone']
      this.spons = routeParams['sponsor']
      const spons = routeParams['sponsor']
     // cserv.checkCountry(this.zone, spons);
    });
  }

    ngOnInit() {
      
      
      $(document).ready( function($){
        let media = <HTMLVideoElement> document.getElementById("mainvideo");
        //const playPromise = media.play();
        //  if (playPromise !== null){
        //    playPromise.catch(() => { media.play(); })
        //  }
            
      
        if (media) {
            media.muted = true;
        }

 

      $('.playvid1').click(function(){
        event.preventDefault();
        $('#vid1').toggle('slow');
        $('#vid2').hide();
        $('#vid3').hide();
      });
      $('.playvid2').click(function(){
        event.preventDefault();
        $('#vid2').toggle('slow');
        $('#vid1').hide();
        $('#vid3').hide();
      });
      $('.playvid3').click(function(){
        event.preventDefault();
        $('#vid3').toggle('slow');
        $('#vid1').hide();
        $('#vid2').hide();
      });
    });
  }

}
