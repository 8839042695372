import { Component, OnInit, ViewChild } from '@angular/core';
import { CountryService } from '../../core/country.service';
import { NgbModal, NgbActiveModal, NgbModalRef, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import {  FormBuilder, FormGroup } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { TranslatePipe } from '../../translate';
import { SponsorService } from '../../core/sponsor.service';

@Component({
  selector: 'app-join-modal',
  templateUrl: './join-modal.component.html',
  styleUrls: ['./join-modal.component.css']
})
export class JoinModalComponent implements OnInit {
  @ViewChild('content') content: any;
  untouched: boolean = true;
  countryForm: FormGroup = this.fb.group({
    zone: null,
  });
  modalRef: NgbModalRef;
  zones$: Observable<any[]>;
  zoneDic: {[country_code: string]: string} = {
    'US': 'united-states',
    'AL': 'albania',
    'AO': 'angola',
    'AG': 'antigua-and-barbuda',
    'AR': 'argentina',
    'MX': 'mexico',
    'AM': 'armenia',
    'AW': 'aruba',
    'AU': 'australia',
    'AT': 'austria',
    'BS': 'bahamas',
    'BB': 'barbados',
  }

  constructor(
    private modalService: NgbModal,
    private cserve: CountryService,
    private config: NgbModalConfig,
    private fb: FormBuilder,
    private afs: AngularFireFunctions,
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private sponsor: SponsorService,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;

    this.route.params.subscribe((routeParams) => {
      console.log(routeParams)
      if (routeParams.zone == undefined) {
        if (this.cserve.zone) {
          
        } else {
          this.cserve.setCountry('united-states')
        }
        this.openSelect()
      } else {

      }

    })
  }

  ngOnInit() {
  }

  submitCC() {
    if (this.countryForm.get('zone').value) {
      // this.country.setCountry(this.countryForm.get('cc').value);
      this.cserve.setCountry(this.countryForm.get('zone').value)
      this.modalRef.close("Submitted");
    } else {
      console.log('No Country')
    }

  }

  async openSelect() {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    await delay(50);
    if (this.untouched) {
      this.untouched = false

      const zonesRef = this.db.collection('zones')
      this.grabCountry()
      this.zones$ = zonesRef.valueChanges();
      this.modalRef = this.modalService.open(this.content, {'centered': true,})

    } else {
      // console.log('touched')
    }
  }

  open(content) {
    this.modalService.open(content);
  }

  async grabCountry() {
     const ccheck = this.afs.httpsCallable('countryCheck');
     ccheck({})
       .toPromise()
       .then(resp => {
         const respDic = JSON.parse(resp)
         // console.log(respDic.country_code)
         // console.log(respDic.country_code+' => '+this.zoneDic[respDic.country_code])
         this.countryForm.get('zone').setValue(this.zoneDic[respDic.country_code])
       })
       .catch(err => {
         console.error({ err });
       });
   }

   bringdownregionform() {
     $('#regionform').toggle("slow");
   }
}
