import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.css']
})
export class TourEditComponent implements OnInit {
  tour_id: string;
  tour;
  saved: boolean;
  isthisnew: boolean;
  trips: any[] = [];
  creatingnewtrip;
  previous: boolean = false;
  pastTrips: any[] = [];
  upcomingTrips: any[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private message: MessageService
  ) {
    this.route.params.subscribe(params => {
      console.log('looking at params')
      if (params['tour_id']) {
        console.log('found a param')
        if (params['tour_id'] != 'new') {
          console.log('not a new one')
          this.isthisnew = false;
          this.tour_id = params['tour_id'];
          this.setTour(this.tour_id);
        } else {
          console.log('going to try to set a new tour')
          this.newTour();
        }
      }
    })
   }

  ngOnInit() {
  }

  setTour(tour_id: string) {
    let tourRef = this.db.doc<any>(`world_tours/${tour_id}`)
    tourRef.valueChanges().subscribe((tour) => {
      if (tour) {
        this.tour = tour;
        console.log(tour);
      }
    })
    let tripsRef = this.db.collection<any>(`world_tours/${tour_id}/trips`)
    tripsRef.valueChanges().subscribe((trips) => {
      if (trips) {
        // console.log(trips);
        trips.map((trip) => {
          let sd = new Date(trip.start_date);
          let now = new Date();
          if (sd > now) {
            this.upcomingTrips.push(trip);
          } else {
            this.pastTrips.push(trip);
          }
        })
        this.trips = this.upcomingTrips;
      }
    })
  }

  localDateString(d: Date): string {
    const YYYY = String(d.getFullYear());
    const MM = d.getMonth()+1>= 10 ? String(d.getMonth()+1): '0'+String(d.getMonth()+1);
    const DD = d.getDate()>= 10 ? String(d.getDate()): '0'+String(d.getDate());
    let hh = d.getHours() >= 10 ? String(d.getHours()): '0'+String(d.getHours());
    let mm = d.getMinutes()>= 10 ? String(d.getMinutes()): '0'+String(d.getMinutes());
    let ss = d.getSeconds()>= 10 ? String(d.getSeconds()): '0'+String(d.getSeconds());
    return YYYY+'-'+MM+'-'+DD+'T'+hh+':'+mm+':'+ss;
  }

  newTour() {
    console.log('Creating a new Tour');
    this.isthisnew = true;
    this.tour = {
      tour_id: '',
      name: '',
      resort: '',
      userstatus: 0,
      uid: null,
      country: '',
      about_location: '',
      about_resort: '',
      city: '',
      siteurl: '',
      imgurl: '',
      banner_imgurl: '',
      perks: [],
      slideshow: [],
      months: [],
      monthYears: []
    }
  }

  slugify(text: string) {
    return text.toString()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^\w-]+/g, '')
      .replace(/--+/g, '-')
  }

  saveTour() {
    //if(this.tour.rooms.length === 0) {
    //} else {
    //  this.tour.rooms.forEach(function (singleroom) {
    //    singleroom
    //  });
    //}
    let data = this.tour;
    data.tour_id = this.slugify(data.name);
    if (!!this.isthisnew) {
      return this.db.doc(`world_tours/${data.tour_id}`).set(data, {merge: true})
        .then(() => {
          console.log('New tour Set!');
          this.saved = true;
          alert("Saved!");
          //this.message.add({severity:'success', summary: 'Success', detail: 'tour Saved'});
        }).catch(err => {console.log(err)});
    } else {
      return this.db.doc(`world_tours/${data.tour_id}`).update(data)
        .then(() => {
          console.log('UPDATED!');
          this.saved = true;
          alert("Saved!");
          //this.message.add({severity:'success', summary: 'Success', detail: 'tour Saved'});
        }).catch(err => {console.log(err)});
    }
  }


  uploadTourPhoto(event) {
    const file = event.target.files[0];
    const filePath = `images/tour/${new Date().getTime()}_${file.name}`
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath,file);
    // observe percentage changes
    // this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().toPromise()
          .then((url) => {
            this.tour.imgurl = url;
          })
      })
    ).subscribe()
  }

  uploadBannerOnePhoto(event, lang) {
    const file = event.target.files[0];
    const filePath = `images/tour/${new Date().getTime()}_${file.name}`
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath,file);
    // observe percentage changes
    // this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().toPromise()
          .then((url) => {
            if(lang=="en"){
              this.tour.promo_1 = url;
              console.log('en sent')
            } else if(lang=="es") {
              this.tour.promo_1_es = url;
              console.log('es sent')
            } else if(lang=="fr") {
              this.tour.promo_1_fr = url;
              console.log('fr sent')
            }

          })
      })
    ).subscribe()
  }

  uploadBannerTwoPhoto(event) {
    const file = event.target.files[0];
    const filePath = `images/tour/${new Date().getTime()}_${file.name}`
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath,file);
    // observe percentage changes
    // this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().toPromise()
          .then((url) => {
            this.tour.promo_2 = url;
          })
      })
    ).subscribe()
  }

  uploadOneSlideShowPhoto(event) {
    const file = event.target.files[0];
    const filePath = `images/tour/${new Date().getTime()}_${file.name}`
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath,file);
    // observe percentage changes
    // this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().toPromise()
          .then((url) => {
            if( this.tour.slideshow ) {
              this.tour.slideshow.push(url);
              event.target.value=null;

            } else {
              this.tour.slideshow = [];
              this.tour.slideshow.push(url);
              event.target.value=null;
            }
          })
      })
    ).subscribe()
  }



  uploadBannerTourPhoto(event) {

    const file = event.target.files[0];
    const filePath = `images/tour/${new Date().getTime()}_${file.name}`
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath,file);
    // observe percentage changes
    // this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().toPromise()
          .then((url) => {
            this.tour.banner_imgurl = url;
          })
      })
    ).subscribe()
  }


  addPerk() {
    if( this.tour.perks ) {
      this.tour.perks.push("");
    } else {
      this.tour.perks = [];
      this.tour.perks.push("");
    }

  }

  removePerk(i) {
    this.tour.perks.splice(i, 1);

  }
  removeSlide(i) {
    this.tour.slideshow.splice(i, 1);

  }

  addMonth() {
    if( this.tour.months ) {
      this.tour.months.push("");
    } else {
      this.tour.months = [];
      this.tour.months.push("");
    }

  }

  addMonthYear() {
    if (this.tour.monthYears) {
      this.tour.monthYears.push("");
    } else {
      this.tour.monthYears = [""];
    }
  }

  removeMonth(i) {
    this.tour.months.splice(i, 1);

  }

  removeMonthYear(i: Number) {
    this.tour.monthYears.splice(i, 1);
  }

  duplicateTrip(trip) {
    console.log(trip)
    let copy = Object.assign({}, trip);
    this.creatingnewtrip = copy;
  }


  saveTrip() {
    //if(this.tour.rooms.length === 0) {
    //} else {
    //  this.tour.rooms.forEach(function (singleroom) {
    //    singleroom
    //  });
    //}
    let data = this.creatingnewtrip;
    data.trip_id = this.slugify(data.trip_title);
    if (this.creatingnewtrip) {
      return this.db.doc(`world_tours/${this.tour.tour_id}/trips/${data.trip_id}`).set(data, {merge: true})
        .then(() => {
          console.log('New trip Set!');
          this.saved = true;
          alert("Saved!");
          this.creatingnewtrip = false;
          //this.message.add({severity:'success', summary: 'Success', detail: 'tour Saved'});
        }).catch(err => {console.log(err)});
    }
  }

  viewPrevious(status: string) {
    if(status == 'yes') {
      $('#previous').addClass('active')
      $('#upcoming').removeClass('active')

      this.previous = true;
      this.trips = this.pastTrips;
    } else {
      $('#upcoming').addClass('active')
      $('#previous').removeClass('active')

      this.previous = false;
      this.trips = this.upcomingTrips;
    }

  }
}
