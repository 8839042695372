import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of, from, BehaviorSubject } from 'rxjs';
import { Country } from './country';
import { Router } from '@angular/router';
import { SponsorService } from './sponsor.service';



@Injectable({
  providedIn: 'root'
})
export class CountryService {
  private _country: BehaviorSubject<Country> = new BehaviorSubject(null)
  private _opener: BehaviorSubject<Boolean> = new BehaviorSubject(false)
  private _region: BehaviorSubject<String> = new BehaviorSubject(null)
  private _caListener: BehaviorSubject<Boolean> = new BehaviorSubject(false)


  constructor(
    private db: AngularFirestore,
    private router: Router,
    private sponsor: SponsorService,
  ) {
    this._country.next(JSON.parse(localStorage.getItem('zone')))
  }

  get country() {
    return this._country.asObservable();
  }
  get opener() {
    return this._opener.asObservable();
  }
  get region() {
    return this._region.asObservable();
  }
  get caListener() {
    return this._caListener.asObservable();
  }


  get zone() {
    const tmp_country = this._country.getValue()
    if (tmp_country) {
      return tmp_country.zone
    } else { return null }
  }

  setCountry(zone: string, sponsor?:string, sb?:boolean) {
    // console.log('Setting Country')
    const zoneRef = this.db.doc(`zones/${zone}`);
    var route = '';
    zoneRef.valueChanges().subscribe((zne: Country) => {
      if (zne) {
        this._country.next(zne)
        localStorage.setItem('zone', JSON.stringify(zne));
        if (sponsor) {
          if (sb){
            route = zone+ '/'+sponsor
          } else {
            return
          }
        } else {
          if (sb){
            route = zone
          } else {
            return
          }
        }
      } else {
        // console.log('rerouting!')
        route = `select-country/${zone}`
      }
      console.log('This Router')
      this.router.navigateByUrl(route)
    })
  }

  newSetCountry(zone: string) {
    const zoneRef = this.db.doc(`zones/${zone}`);
    zoneRef.valueChanges().subscribe((zne: Country) => {
      if (zne) {
        this._country.next(zne)
      } else {
        console.log('non-valid country');
      }
    })
  }


  checkCountry(zone: string, sponsor?: string) {
    console.log('Checking CountryService')
    var tmp_country = this._country.getValue()
    if (tmp_country) {
      if (tmp_country.zone === zone) {
        // console.log('keeping Zone!')
        return
      }
    }
    // console.log('Changing Zone!')
    // return this.setCountry(zone, sponsor)
  }

  joinCheck(): boolean {
    const tmp_country = this._country.getValue()
    if (tmp_country) {
      return true
    } else { return false }
  }

  openerFlip() {
    this._opener.next(true);
  }
  openerOff() {
    this._opener.next(false);
  }

  setRegion(r: string) {
    this._region.next(r);
  }

  caOn() {
    this._caListener.next(true);
  }
  caOff() {
    this._caListener.next(false);
  }

}
