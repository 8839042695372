import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NgxMaskModule } from 'ngx-mask';


import { TRANSLATION_PROVIDERS, TranslatePipe, TranslateService }  from '../translate';
import { SelectLangComponent } from './select-lang/select-lang.component';
import { FooterComponent } from './footer/footer.component';


import { LightboxModule } from 'primeng/lightbox';
import {SidebarModule} from 'primeng/sidebar';
import {TabViewModule} from 'primeng/tabview';

import { JoinModalComponent } from './join-modal/join-modal.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { DreamcationComponent } from './dreamcation/dreamcation.component';

import { CcModalComponent } from './cc-modal/cc-modal.component';


import { FreeVacationIncentiveComponent } from './free-vacation-incentive/free-vacation-incentive.component';
import { EnrollComponent } from './enroll/enroll.component';

import { WetripComponent } from './wetrip/wetrip.component';
import { NouisliderModule } from 'ng2-nouislider';
import { PartnershipComponent } from './partnership/partnership.component';
import { CompplanComponent } from './compplan/compplan.component';
import { LaunchComponent } from './launch/launch.component';
import { HeadComponent } from './wetrip/head/head.component';
import { WorldtoursComponent } from './worldtours/worldtours.component';
import { TourComponent } from './tour/tour.component';
import { TermsComponent } from './terms/terms.component';
import { TripComponent } from './trip/trip.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { BookedComponent } from './booked/booked.component';
import { NewBookingComponent } from './new-booking/new-booking.component';
import { ThinFooterComponent } from './thin-footer/thin-footer.component';




@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    LightboxModule,
    SidebarModule,
    TabViewModule,
    NgbModule,
    NouisliderModule,
    NgxMaskModule.forRoot(),
  ],
  declarations: [ HomeComponent,
    NavBarComponent,

    TranslatePipe,
    SelectLangComponent,
    FooterComponent,

    JoinModalComponent,
    DreamcationComponent,
    CcModalComponent,
    FreeVacationIncentiveComponent,
    EnrollComponent,
    WetripComponent,
    PartnershipComponent,
    CompplanComponent,
    LaunchComponent,
    HeadComponent,
    WorldtoursComponent,
    TourComponent,
    TermsComponent,
    TripComponent,
    CheckoutComponent,
    BookedComponent,
    NewBookingComponent,
    ThinFooterComponent],
  exports: [CcModalComponent]
})
export class UiModule { }
