import { Component, OnInit, OnDestroy } from '@angular/core';
import { WorldtourService } from '../../core/worldtour.service';
import { IdService } from '../../core/id.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '../../translate';
import * as $ from 'jquery';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { log } from "util";

@Component({
  selector: 'app-booked',
  templateUrl: './booked.component.html',
  styleUrls: ['./booked.component.css']
})
export class BookedComponent implements OnInit {
  id: any;
  seqID: string;
  loader: boolean = true;
  bookings: any[] = [];
  selectedBooking: any = null;
  success = false;
  previous: boolean = false;
  pastBookings: any[] = [];
  upcomingBookings: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private functions: AngularFireFunctions,
    private wts: WorldtourService,
    private auth: IdService,
    private router: Router
  ) {
    this.route.queryParams.subscribe(async params => {
      await new Promise(async (resolve, reject) => {
        if (params['id'] && params['hash']) {
          this.id = params['id'];
          if (!this.auth.verify(this.id,params['hash'])) {
            console.log('Failed Verification')
            window.location.replace("https://backoffice.travorium.com/travorium/member_new2/welcome.asp");
          }
          console.log('Pass Verification');
          await this.wts.loadTours().then((status) => {
            if (status) {
              this.loader = false;
            } else {
              reject({status: 'failed', message: 'Failed to load tours'});
            }
          })
          resolve(true);
        } else if (auth.salted) {
          console.log('Previously Salted');
          this.id = this.auth.id
          resolve(true);
        } else {
          window.location.replace("https://backoffice.travorium.com/travorium/member_new2/welcome.asp");
          reject(false);
        };
      }).then((status) => {
        this.route.params.subscribe((prams) => {
          if (prams['seqID']) {this.seqID = prams['seqID'];}
          this.loadBooking([])
        })
      }).catch((error) => {
        console.log(error);
      })

    });
  }

  ngOnInit() {
  }

  async loadBooking(ids: string[]){
    return new Promise(async (resolve) => {
      let books = [];
      let new_bookings = [];
      let list = Promise.all(this.wts.booked.map(async booking => {
        //console.log(booking);
        return this.db.collection('wt_travelers').doc(String(booking.SaleData.Seq)).valueChanges().subscribe((_booking: any) => {
          if (_booking) {
            //  console.log(_booking.trip_info.start_date);
             //console.log(booking.SaleData);
             let room: any;
             let _trips = this.wts.serveTour(booking.SaleData.tour_info.tour_id).trips as any[];
             let trip = _trips.find((trip) => { return trip.trip_id == booking.SaleData.trip_info.trip_id });
             if (trip) {
               // Pull Booked Trip Data here!
               //console.log(trip);
               if (trip.booked_description) {
                 _booking.booked_description = trip.booked_description;
               }
               let _rooms = trip.rooms as any[];
               room = _rooms.find((room) => { return room.id == booking.SaleData.room_id });
             } 
             if (room) {
               //console.log(room);
               _booking.room = room;
             }

             let now = new Date();
             if (now < new Date(_booking.trip_info.start_date)) {
              //console.log(_booking);
              this.upcomingBookings.push(_booking);
             } else {
              this.pastBookings.push(_booking);
             }
             books.push(_booking);
          } else {
              // Create New Booking
              new_bookings.push(this.createBooking(booking));
          }
        })
      }))

      return list.then(async (test) => {
        // let now = new Date();
        this.bookings = this.upcomingBookings; 
        // console.log(books);
        // await books.map(async trip => {
        //   let start = trip.trip_info.start_date;
        //   console.log(start);
        // })
        // let upcoming = await books.filter((trip) => true);
        // console.log(books);
        // let upcoming = books.filter(booking => {
        //   // let startDate = new Date(booking.trip_info.start_date);
        //   // console.log(startDate);
        //   return booking.trip_info.start_date
        // })
        // console.log(upcoming);
        if (this.seqID){
          this.selectBooking(this.seqID, books);
        } else {
          this.loader = false;
          console.log('no seqID');
        }
        return resolve(books);
      })
    })
  }

  async selectBooking(seqID: string, bookings: any[]=this.bookings) {
    const delay = ms => new Promise(_ => setTimeout(_, ms));
    await delay(200).then(() => {})
    this.selectedBooking = bookings.find(booking => {
      return (booking.seqID == seqID)
    })
    console.log(this.selectedBooking);
    this.loader = false;
  }

  updateBooking() {
    console.log(this.selectedBooking.traveler_info)
    this.db.doc(`wt_travelers/${this.selectedBooking.seqID}`).update({traveler_info: this.selectedBooking.traveler_info})
      .then(result => {
        console.log(result);
        this.selectBooking(null);
        scroll(0,0);
      })
  }

  createBooking(booking): Promise<void> {
      console.log(booking);
      let bookingRef = this.db.doc(`/wt_travelers/${booking.SaleData.Seq}`);
      let data = {
        seqID: booking.SaleData.Seq,
        cs_id: this.id,
        tour_info: booking.SaleData.tour_info,
        trip_info: booking.SaleData.trip_info,
        traveler_info: [{name: booking.SaleData.Name, dob: '', type: 'adult'}],

        adults: booking.SaleData.Adults,
        children: booking.SaleData.Children,
        amount: booking.SaleData.Amount,
        extra_total: booking.SaleData.ExtraTotal,
        points: booking.SaleData.Points,
        trans_date: booking.SaleData.TransDate
      };
      
      data.traveler_info.push(...Array(data.adults-1).fill({
          name: '',
          dob: '',
          type: 'adult'
      }));
      data.traveler_info.push(...Array(data.children).fill({
          name: '',
          dob: '',
          type: 'child'
      }));
      let prom = bookingRef.set(data);
      return prom
  } 

  viewPrevious(status: string) {
    if(status == 'yes') {
      $('#previous').addClass('active')
      $('#upcoming').removeClass('active')

      this.previous = true;
      this.bookings = this.pastBookings;
    } else {
      $('#upcoming').addClass('active')
      $('#previous').removeClass('active')
      
      this.previous = false;
      this.bookings = this.upcomingBookings;
    }
    
  }

}
