import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-edit-booking',
  templateUrl: './edit-booking.component.html',
  styleUrls: ['./edit-booking.component.css']
})
export class EditBookingComponent implements OnInit {
  seqID: string = '';
  booking: any;

  search_tour_id: string;
  search_trip_id: string;
  backUrl = '/admin/bookings';

  confDelete: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private router: Router
  ) {
    this.route.params.subscribe((prams) => {
      if (prams['seqID']) {
        this.seqID = prams['seqID'];
        this.loadBooking(this.seqID);
      } else {
        this.router.navigateByUrl('/admin/bookings');
      }
    })
    this.route.queryParams.subscribe((params) => {
      if (params['tour_id']) {
        this.backUrl = `/admin/bookings?tour_id=${params['tour_id']}`;
        this.search_tour_id = params['tour_id'];
      }
      if (params['trip_id']) {
        this.backUrl = this.backUrl + `&trip_id=${params['trip_id']}`;
        this.search_trip_id = params['trip_id'];
      }
    })
  }

  ngOnInit() {
  }

  loadBooking(seqID: string) {
    this.db.doc(`wt_travelers/${seqID}`).valueChanges().subscribe((booking) => {
      if (booking) {
        this.booking = booking;
      }
    })
  }

  updateBooking() {
    // console.log(this.booking.traveler_info)
    this.db.doc(`wt_travelers/${this.booking.seqID}`).update({traveler_info: this.booking.traveler_info})
      .then(result => {
        console.log(result);
        alert('Booking updated successfully');
        scroll(0,0);
      }).catch((err) => {
        alert('There was an error updating the booking');
        console.log(err);
      })
  }

  updateBookingBID() {
    
    this.db.doc(`wt_travelers/${this.booking.seqID}`).update({rid: this.booking.rid})
      .then(result => {
        console.log(result);
        alert('Booking updated successfully');
        scroll(0,0);
      }).catch((err) => {
        alert('There was an error updating the booking');
        console.log(err);
      })
      
   }

  deleteBooking() {
    if (this.confDelete) {
      console.log('deleting...');
      this.db.doc(`wt_travelers/${this.booking.seqID}`).delete().then(() => {
        alert('Booking deleted successfully');
        this.router.navigateByUrl(this.backUrl);
      }).catch((err) => {
        alert('There was an error deleting the booking');
        console.log(err);
      });
    } else {
      alert('You are trying to delete this booking. Please confirm by clicking Delete again.');
      this.confDelete = true;
    }

  }

  addnewperson() {
    this.booking.traveler_info.push({
      dob: "",
      name: "",
      type: ""
    })
  }

}
