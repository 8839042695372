import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class IdService {
  private salt = "6481a638-9347-4211-92d7-f98137d260cd";
  private _id: string;
  private _hash: string;
  private _salted: boolean = false;

  private _level: string;
  private _points: number;

  constructor(

  ) { }

  get salted() {
    return this._salted
  }

  get id() {
    return this._id
  }

  get points() {
    return this._points
  }

  get rank() {
    return this._level
  }

  verify(id: string, hash: string) {
    console.log(CryptoJS.MD5(id+"-"+this.salt).toString());
    if (hash == CryptoJS.MD5(id+"-"+this.salt).toString()) {
      this._hash = hash
      this._id = id
      this._salted = true
    }
    return this.salted;
  }

  updateUser(level: string, points: any) {
    this._level = level
    this._points = Number(points)
  }
}
