import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Country } from '../../core/country';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-view-zones',
  templateUrl: './view-zones.component.html',
  styleUrls: ['./view-zones.component.css']
})
export class ViewZonesComponent implements OnInit {
  zones$: Observable<Country[]>

  constructor(
    private db: AngularFirestore,
    private router: Router,
  ) {
    this.zones$ = db.collection<Country>('zones').valueChanges();
  }

  ngOnInit() {
  }

}
