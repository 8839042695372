import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-world-tours',
  templateUrl: './world-tours.component.html',
  styleUrls: ['./world-tours.component.css']
})
export class WorldToursComponent implements OnInit {
tours: any[];
archived: any[]

  constructor(
    private db: AngularFirestore,
    private storage: AngularFireStorage,
  ) {
    this.loadTours();
  }

  ngOnInit() {
  }

  loadTours() {
    let toursRef = this.db.collection<any>(`world_tours`, ref => {

      return ref;
    })
    toursRef.valueChanges().subscribe((tours) => {
      if (tours) {
        this.tours = tours;
        let _tours = tours.map(tour => {
          tour.start_date_sortable = new Date(tour.start_date+'T01:01:01+08:00');
          tour.end_date_sortable = new Date(tour.end_date+'T01:01:01+08:00');
          tour.finalpaymentdue_sortable = new Date(tour.finalpaymentdue);
          
          return tour
        })
        this.archived = _tours.filter((tour) => tour.archived)
        _tours = _tours.filter((tour) => !tour.archived)
        this.tours = _tours.sort((s1,s2) => {
          return s1.start_date_sortable - s2.start_date_sortable
        })
      }
    })
  }



}
