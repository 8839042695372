import { Component, OnInit } from '@angular/core';
import { WorldtourService } from '../../core/worldtour.service';
import { IdService } from '../../core/id.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '../../translate';
import { NouisliderModule } from 'ng2-nouislider';
import * as $ from 'jquery';
import * as CryptoJS from 'crypto-js';
import { retry } from 'rxjs/operators';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  id: string;
  tour: any;
  trip: string;
  room: any;
  tripname: string;

  booklink = "https://backoffice.travorium.com/travorium/enroll_new4/cc_auth_WETRIP_NEW.asp?id=";

  constructor(
    private route: ActivatedRoute,
    private wts: WorldtourService,
    private auth: IdService,
    private router: Router
  ) {
    this.route.queryParams.subscribe(params => {
      if (params['id'] && params['hash']) {
        this.id = params['id'];
        if (!this.auth.verify(this.id,params['hash'])) {
          console.log('Failed Verification')
          window.location.replace("https://travorium.com");
        }
        console.log('Pass Verification');
      } else if (auth.salted) {
        console.log('Previously Salted');
        this.id = this.auth.id
      } else {
        
        window.location.replace("https://backoffice.travorium.com/travorium/member_new2/welcome.asp");
      };

      this.route.params.subscribe((prams) => {
        if (prams['tour-id']) {
          this.tour = this.wts.serveTour(prams['tour-id'])
          this.tripname = prams['trip-id']
          if (prams['trip-id'] && prams['room-id']) {
            this.trip = prams['trip-id']
            this.room = this.serveRoom(prams['trip-id'], prams['room-id'])
            this.room.adults = params['ea']
            this.room.child= params['ae']
            this.room.addonscost = this.calcAdoncost();
          }
        }
      })
    });
  }

  ngOnInit() {
  }

  serveRoom(trip_id: string, room_id: string) {
    return this.tour.trips.find(trip => trip.trip_id == trip_id).rooms.find(room => room.id == room_id)
  }

  sliderMinimum(room) {
    let dollaramountinpoints = this.auth.points / 2;
    let lowestprice = 0;
    if(this.auth.rank == "T") {
      lowestprice = room.lowestprice_t;
    } else {
      lowestprice = room.lowestprice_p;
    }
    let thedifference = room.regularprice - dollaramountinpoints;
    if( thedifference < lowestprice) {
      return lowestprice;
    } else {
      return thedifference;
    }
  }

  calcAdoncost() {
    return (this.adultCost() + this.childCost())
  }

  pointsUsed() {
    return (this.room.regularprice - this.room.amounttopay) * 2
  }

  adultCost(): number {
    let adult = (this.room.adults - this.room.adultsincluded)*this.room.costofadult
    return adult > 0 ? adult * this.room.nights : 0;
  }

  childCost(): number {
    return this.room.child * this.room.costofchildren * this.room.nights
  }

  

  bookBuilder(flag: string, discountdollar) {
    let thepay = 0;
    let fullhash;
    if (flag == 'D') {
      fullhash = this.tour.uid+'|'+this.room.tripid+'|'+this.room.id+'|'+this.room.name+','+flag+'|'+this.room.depositcost+'|'+this.pointsUsed()+'|'+this.room.adults+'|'+this.room.child+'|'+this.room.addonscost;
    } else if (flag == 'B') {
      
      if(this.room.freekid && this.room.child > 0) {
        let minusamount = this.room.costofchildren * this.room.nights;
        this.room.addonscost = this.room.addonscost - minusamount;
      }
      if (discountdollar) {
        thepay = this.room.amounttopay + this.room.addonscost - discountdollar;
      } else {
        thepay = this.room.amounttopay + this.room.addonscost;
      }
      
      fullhash = this.tour.uid+'|'+this.room.tripid+'|'+this.room.id+'|'+this.room.name+','+flag+'|'+thepay+'|'+this.pointsUsed()+'|'+this.room.adults+'|'+this.room.child+'|'+(this.room.addonscost > 0? this.room.addonscost : '0')+'|'+(this.room.variant? this.room.variant : '0');
    }
    console.log(fullhash);

    this.wts.roomAvailability(this.tour.uid,this.room.tripid,this.room.id).then(available => {
      if (available) {
        this.bookem(fullhash);
      } else {
        console.log('Fully Booked or Error');
        alert('This room is fully booked or there was an error. Please try again later.');
      }
    }).catch(err => {
      console.log(err);
    })
  }

  bookem(hash) {
    let fullhash = CryptoJS.enc.Utf8.parse(hash);
    var encrypted = CryptoJS.enc.Base64.stringify(fullhash);
    let fullink = this.booklink+this.id+'&hash='+encrypted;
    //console.log(fullink);
    window.location.href = fullink;
  }
}
