import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { LightboxModule } from 'primeng/lightbox';
import { SidebarModule } from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';
import { NouisliderModule } from 'ng2-nouislider';
import { NgxMaskModule } from 'ngx-mask';

// Firebase Modules
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { environment } from '../environments/environment';

// App Modules and Components
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './/app-routing.module';
import { UiModule } from './ui/ui.module';
import { AdminModule } from './admin/admin.module';
import { CookieService } from 'angular2-cookie/services/cookies.service';
import { TRANSLATION_PROVIDERS, TranslatePipe, TranslateService }  from './translate';
import { GoogleAnalyticsService } from './core/google-analytics.service';
import { MessageService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AppRoutingModule, // imports firebase/storage only needed for storage features
    UiModule,
    AdminModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    LightboxModule,
    SidebarModule,
    TabViewModule,
    NouisliderModule,
    ToastModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    CookieService,
    TRANSLATION_PROVIDERS,
    TranslateService,
    GoogleAnalyticsService,
    MessageService
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }
