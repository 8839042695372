import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-import-bookings',
  templateUrl: './import-bookings.component.html',
  styleUrls: ['./import-bookings.component.css']
})
export class ImportBookingsComponent implements OnInit {
  uploadBookings = new FormGroup({
    file: new FormControl(null),
    processed: new FormControl(null, Validators.required)
  });
  public fileString;
  preUpload: any[] = [];
  fileLength: number = 0;
  err: string | null = null;
  locations: {[uid: number]: any} = {};
  progress: {loaded: number, total: number, step: string, counter: boolean} = {loaded: 0, total: 0, step: null, counter: false};

  constructor(
    private db: AngularFirestore,
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    this.grab_trips().then(() => {
       return this.build_wt_travelers();
    }).then((toUpload) => {
      this.upload_bookings(toUpload);
    }).catch(err => {})
  }

  onFileChange($event) {
    if ($event.target.files.length > 0) {
      this.readThis($event.target);
    }
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    // console.log(file);
    var myReader: FileReader = new FileReader();
    var fileType = file.type;
    console.log('fileType: ' + fileType);
    if (fileType === 'text/csv') {
      myReader.onloadend = (e) => {
        // console.log(myReader.result);
        this.fileString = myReader.result as string;
        // console.log(this.fileString);
        let lines = (<string>myReader.result).split(/\r?\n/);
        // console.log((<string>myReader.result).split(/\r?\n/));
        try {
          // Get Headers
          let headers = lines.shift().split(',');
          const date_index = headers.indexOf('TripDates');
          headers.splice(date_index + 1, 0, 'Year');

          // Get Data
          let data = lines.map(line => {
            // line = line.replace("\\\"", "");
            if (!headers.includes('Year')){
              headers.splice(date_index + 1, 0, 'Year');
              // console.log('ReAdd Year to Header');
            }
            let row = line.split(',');
            let tmp_headers = headers;
            if (row.length == headers.length) {
            } else if (row.length < headers.length) {
              tmp_headers.splice(date_index + 1, 1);
              // console.log(row);
            }
            let booking = row.reduce((obj, value, index) => {
              obj[tmp_headers[index]] = value; 
              return obj;
            }, {});
            if (booking['Year']) { // if Year is row
              booking['TripDates'] = booking['TripDates'] + ',' + booking['Year'];
              delete booking['Year'];
            }
            return booking;
          });
          // console.log(data);
          this.preUpload = data;
          this.fileLength = data.length;
          this.uploadBookings.value['processed'] = true;
          // console.log(this.uploadBookings);
        } catch (e) {
          console.log(e);
          this.err = "There was an error processing the file. Please check the file and try again.";
        }
      };
      myReader.readAsText(file);

    } else {
      this.err = 'Please upload a CSV file';
      return
    }
  }

  async grab_trips() {
    let used_locations = [];
    let used_trips = [];
    let tmp_bookings = this.preUpload;
    this.progress.step = 'Grabbing Trips Data...';
    this.progress.counter = true;
    this.progress.total = this.preUpload.length;
    this.progress.loaded = 0;
    while (tmp_bookings.length > 0) {
      let locationID = tmp_bookings[0].LocationID;
      let location_bookings = tmp_bookings.filter(booking => {
        return booking.LocationID === locationID;
      })
      let locationRef = this.db.collection<any>('world_tours', ref => {
        return ref.where('uid', '==', Number(locationID));
      });
      // this.locations[locationID] = await locationRef.valueChanges().pipe(take(1)).toPromise();
      await locationRef.valueChanges().pipe(take(1)).toPromise().then(async locations => {
        this.locations[locationID] = locations[0];
        this.locations[locationID].trips = {};
        while (location_bookings.length > 0) {
          let tripID = location_bookings[0].TripID;
          used_trips.push(tripID);
          let tripRef = this.db.collection<any>(`world_tours/${locations[0].tour_id}/trips`, ref => { 
            return ref.where('uid', '==', Number(tripID));
          });
          await tripRef.valueChanges().pipe(take(1)).toPromise().then(trips => {
            this.locations[locationID].trips[tripID] = trips[0];
            // console.log("trip: " + trips[0].uid + " added to location: " + locationID);
          })
          location_bookings = location_bookings.filter(booking => {
            return !(used_trips.includes(booking.TripID));
          })
        }
      });

      used_locations.push(locationID);
      console.log("filtering out " + locationID);
      tmp_bookings = tmp_bookings.filter(booking => {
        return !(used_locations.includes(booking.LocationID));
      })
      this.progress.loaded = this.progress.total - tmp_bookings.length
    }
    // console.log(used_locations);
    // console.log(used_trips);
    // console.log(this.locations);
    return true;
  }

  async build_wt_travelers() {
    console.log("building travelers");
    this.progress.step = 'Building WT Travelers';
    this.progress.loaded = 0;
    this.progress.counter = true;
    let forUpload = [];
    // const testingSlice = this.preUpload.slice(0, 5);
    // this.progress.total = testingSlice.length;
    this.progress.total = this.preUpload.length 
    for (const data of this.preUpload) {
    // for (const data of testingSlice) {
      let bookingRef = this.db.doc<any>(`/wt_travelers/${data.Seq}`)
      const doc = await bookingRef.get().toPromise();
      if (!doc.exists) {
        // console.log(data);
        let booking: any = {
          seqID: Number(data.Seq),
          adults: data.Adults,
          children: Number(data.Children),
          amount: data.Amount,
          extra_total: data.ExtraTotal,
          points: data.Points,
          trans_date: data.TransDate,
          cs_id: data.ID,
          traveler_info: [{name: data.Name, dob: '', type: 'adult'}]
        }
        booking.traveler_info.push(...Array(booking.adults-1).fill({
          name: '',
          dob: '',
          type: 'adult'
        }))
        booking.traveler_info.push(...Array(booking.children).fill({
          name: '',
          dob: '',
          type: 'child'
        }))
        let tour = this.locations[data.LocationID];
        booking.tour_info = {
          tour_id: tour.tour_id,
          city: tour.city,
          country: tour.country,
          name: tour.name,
          imgurl: tour.imgurl,
          uid: tour.uid
        }
        let trip = tour.trips[data.TripID];
        booking.trip_info = {
          end_date: trip.end_date,
          start_date: trip.start_date,
          trip_id: trip.trip_id,
          trip_title: trip.trip_title,
          uid: trip.uid
        }
        booking.importer = true;
        console.log(booking);
        forUpload.push(booking);
        this.progress.loaded++;
      } else {
        console.log('skipping ' + data.Seq);
      }
    }
    // console.log(forUpload);
    return forUpload;
  }

  async upload_bookings(bookings: any[]) {
    console.log("uploading bookings");
    // let upBookings = bookings.slice(0, 5);
    this.progress.step = 'Uploading Bookings';
    this.progress.total = bookings.length;
    // this.progress.total = upBookings.length;
    this.progress.loaded = 0;
    this.progress.counter = true;
    // for (const booking of upBookings) {
    for (const booking of bookings) {
      let bookingRef = this.db.doc<any>(`/wt_travelers/${booking.seqID}`)
      await bookingRef.set(booking).then(() => {
        this.progress.loaded++;
      }).catch(err => {
        console.log(err);
      })
    }
    this.progress.step = 'Done';
    this.progress.counter = false;
    return true;
  }
}
