import { Component, OnInit } from '@angular/core';
import { SponsorService } from '../../core/sponsor.service';
import { ActivatedRoute } from '@angular/router';
import { TranslatePipe } from '../../translate';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private sponsor: SponsorService,
  ) {
    route.params.subscribe((routeParams) => {
      if (routeParams.sponsor) {
        if(routeParams.zone) {
          console.log('yes zone')
          sponsor.checkSponsor(routeParams.sponsor, routeParams.zone)
        }
        else {
          console.log('no zone')
          sponsor.checkSponsorJoin(routeParams.sponsor)
        }
      } else (
        sponsor.checkCookie()
      )
    });
  }

  ngOnInit() {
  }

}
