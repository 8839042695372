import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { SponsorService } from './sponsor.service'


@Injectable({
  providedIn: 'root'
})
export class SponsorZoneGuard implements CanActivate {
  constructor(
    private afs: AngularFirestore,
    private router: Router,
    private sponsor: SponsorService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      this.sponsor.touched.subscribe((touched) => {
        if (touched) {
          return true
        }
      });
      const sponsor = next.params['sponsor']
      const zone = next.params['zone']
      return this.afs.collection('zones').doc(zone).get()
        .toPromise().then((documentSpapshot): boolean => {
          if (documentSpapshot.exists) {
            console.log('using... '+zone+' '+sponsor)
            this.sponsor.reached()
            // return this.sponsor.gaurdVerify(sponsor).then((res) : boolean => {
            //   if (res) {
            //     return true
            //   } else {
            //     this.router.navigateByUrl(zone)
            //   }
            // })
            return true
            // if (this.sponsor.gaurdVerify(sponsor)) {
            //   console.log('Letting');
            //   return true
            // } else {
            //   console.log('Booting');
            //   this.router.navigateByUrl(zone)
            //   return false
            // }
          } else {
            console.log('Doc no exist');
            // return this.sponsor.gaurdVerify(sponsor).then((res) : boolean => {
            //   if (res) {
            //     this.router.navigateByUrl(`select-country/${sponsor}`)
            //     return false;
            //   } else {
            //     this.router.navigateByUrl('')
            //     return false;
            //   }
            // })
            this.router.navigateByUrl(`select-country/${sponsor}`)
            return false
          }
    })
  }
}
