import { Component, OnInit } from '@angular/core';
import { WorldtourService } from '../../core/worldtour.service';
import { IdService } from '../../core/id.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '../../translate';

import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import {DomSanitizer} from '@angular/platform-browser';
import { range } from 'rxjs';
declare var $: any;


@Component({
  selector: 'app-worldtours',
  templateUrl: './worldtours.component.html',
  styleUrls: ['./worldtours.component.css']
})
export class WorldtoursComponent implements OnInit {
  seqid: any;
  id: any;
  trips: any[];
  loader: boolean = true;
  months: string[] = [];
  prev_months: string[] = [];
  _months: string[] = ['January','February','March','April','May','June','July','August','September','October','November','December'];
  featured = [];
  booked_length: number = 0;

  constructor(
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private functions: AngularFireFunctions,
    private wts: WorldtourService,
    private auth: IdService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {
    this.route.queryParams.subscribe(params => {
      if (params['id'] && params['hash']) {
        this.id = params['id'];
        if (!this.auth.verify(this.id,params['hash'])) {
          console.log('Failed Verification')
          window.location.replace("https://backoffice.travorium.com/travorium/member_new2/welcome.asp");
        }
        // console.log('Pass Verification');
      } else if (auth.salted) {
        // console.log('Previously Salted');
        this.id = this.auth.id
      } else {
        window.location.replace("https://backoffice.travorium.com/travorium/member_new2/welcome.asp");
      };

      if (this.wts.destinations) {
        console.log('Would Skip Loading Destinations');
        this.loader = false;
        this.booked_length = this.wts.booked.length;
        this.filterByFeatured().then((featured) => {
          console.log("run the slider");
          this.activate()
        })
      } else {
        console.log('Loading Destinations');
        this.wts.loadTours().then((status) => {
          if (status) {
            this.loader = false;
            this.booked_length = this.wts.booked.length;
            this.filterByFeatured().then((featured) => {
              console.log("run the slider");
              this.activate()
            })

          }

        })
      }
    });
    this.orderMonths();
  }

  ngOnInit() {
  }

  async orderMonths() {
    let now = new Date();
    let cur_month = now.getMonth()
    let cur_year = now.getFullYear()
    let _months_ = [];
    let _prev_months = [];
    for (let i = 0; i < 12; i++) {
      if (i + cur_month < 12) {
        _months_.push(this._months[i+cur_month]+"_"+cur_year)
        // This line shows all of last year in previous
        _prev_months.unshift(this._months[i + cur_month]+"_"+(cur_year-1))
      } else {
        _months_.push(this._months[i - (12 - cur_month)]+"_"+(cur_year+1))
        _prev_months.unshift(this._months[i - (12 - cur_month)]+"_"+cur_year)
      }
    }

    console.log("Previous Months... ", _prev_months);
    this.prev_months = _prev_months;
    this.months = _months_;
  }

  filterByMonth(month: string) {
    return this.wts.destinations.filter(tour => {
      if (tour.active || this.id == 10000) {
        return (tour.months.includes(month))
      }

    })
  }

  filterByMonthYear(monthYear: string) {
    return this.wts.destinations.filter(tour => {
      if (tour.active || this.id == 10000) {
        return (tour.monthYears.includes(monthYear))
      }

    })
  }

  async filterByFeatured() {
    let tmp_tours = this.wts.destinations.filter(tour => {
      return (tour.featured == true)
    })
    for (let i in tmp_tours ){
      // console.log(tmp_tours[i]);
      let lowest_p = 10000;
      let lowest_t = 10000;
      for (let j in tmp_tours[i].trips) {
        for (let k in tmp_tours[i].trips[j].rooms) {
            if (!!!tmp_tours[i].trips[j].rooms[k].active) { continue }

            if(tmp_tours[i].trips[j].rooms[k].discounted) {
              if (tmp_tours[i].trips[j].rooms[k].lowestprice_p - tmp_tours[i].trips[j].rooms[k].discounted_dollar < lowest_p ) {
              lowest_p = tmp_tours[i].trips[j].rooms[k].lowestprice_p - tmp_tours[i].trips[j].rooms[k].discounted_dollar;

              }
              if (tmp_tours[i].trips[j].rooms[k].lowestprice_t - tmp_tours[i].trips[j].rooms[k].discounted_dollar < lowest_t ) {

                lowest_t = tmp_tours[i].trips[j].rooms[k].lowestprice_t - tmp_tours[i].trips[j].rooms[k].discounted_dollar;
              }
            } else {
              if (tmp_tours[i].trips[j].rooms[k].lowestprice_p < lowest_p) {
                lowest_p = tmp_tours[i].trips[j].rooms[k].lowestprice_p;

              }
              if (tmp_tours[i].trips[j].rooms[k].lowestprice_t < lowest_t) {

                lowest_t = tmp_tours[i].trips[j].rooms[k].lowestprice_t;
              }

            }


        }
        // console.log(tmp_tours[i].tours[j]);
      }
      tmp_tours[i].lowestprice_p = lowest_p;
      tmp_tours[i].lowestprice_t = lowest_t;
    }

    this.featured = tmp_tours.filter((tour) => {
      return (tour.lowestprice_t != 10000)
    });
    // console.log(tmp_tours);
    return new Promise((resolve, reject) => {
      resolve(true);
    })
  }

  hasMonths(month: string) {
    return this.wts.destinations.filter(tour => {
        return (tour.months.includes(month))
      }).length > 0
  }

  hasMonthYear(monthYear: string) {
    return this.wts.destinations.filter(tour => {
        return (tour.monthYears.includes(monthYear))
      }).length > 0
  }

  sany(value: any, args?: any): any {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${value})`);

  }

  async  activate() {
    const delay = ms => new Promise(_ => setTimeout(_, ms));
    await delay(.05).then(() => {})

    $('.slider-active').owlCarousel({
      loop: true,
      nav: true,
      autoplay: true,
      navText: ['<i class="fa fa-chevron-left makeitwhite"></i>', '<i class="fa fa-chevron-right makeitwhite"></i>'],
      autoplayTimeout: 6000,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
      item: 1,
      responsive: {
        0: {
          items: 1
        },
        768: {
          items: 1
        },
        1000: {
          items: 1
        }
      }
    })

  }

}
