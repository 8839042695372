export const LANG_EN_NAME = 'en';

export const LANG_EN_TRANS = {
    'hello world': 'hello world',
    'test trans': 'test trans',
    'Your Dreams Made Possible': 'Your Dreams Made Possible',
    'By the Collective Power of our Membership': 'By the Collective Power of our Membership',
    'Bringing Value and a Winning Opportunity to the Marketplace': 'Bringing Value and a Winning Opportunity to the Marketplace',

    
};
