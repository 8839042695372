import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, take, first } from 'rxjs/operators';
import { saveAs } from 'file-saver';



@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.css']
})
export class BookingsComponent implements OnInit {
  bookings$: Observable<any>;
  searchForm: FormGroup;
  query: Subject<any|null>;

  tour_id: string = '';
  trip_id: string = '';
  tours: any[];
  trips: any[];

  flag: boolean;
  count: number = null;

  constructor(
    private db: AngularFirestore,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  async ngOnInit() {
    this.buildForm();
    this.getTours();

    this.query = new Subject();
    this.bookings$ = this.query.pipe(
      switchMap((query) => {
        if (query) {
          var end = new Date(query.end);

          //if (query.tour_id = " ") {
          //    return this.db.collection('wt_travelers', ref => ref.orderBy("seqID")).valueChanges();
          //}

          switch(query.trip_id) {
            case '': {
              return this.db.collection('wt_travelers', ref => ref.where('tour_info.tour_id', '==', query.tour_id)).valueChanges();
            }
            default: {
              return this.db.collection('wt_travelers', ref => ref.where('tour_info.tour_id', '==', query.tour_id).where('trip_info.trip_id', '==', query.trip_id)).valueChanges();
            }
          }
        } else {
          // console.log("n0 query");
          return of(null);
        }

      }));
      this.onFormChange();
      this.route.queryParams.subscribe(params => {
        if (params.tour_id) {
          if (params.trip_id) {
            this.searchForm.setValue({tour_id: params.tour_id, trip_id: params.trip_id, field: 'tour_trip'});
          } else {
            this.searchForm.setValue({tour_id: params.tour_id, trip_id: '', field: 'tour_id'});
          }
          this.setTour(params.tour_id);
          this.hardSearch(true);
        }
      })
  }

  buildForm() {
    const end = new Date();
    this.searchForm = this.fb.group({
      field: '',
      tour_id: '',
      trip_id: '',
    });
  }

  formateDate(date) {
    var d = new Date(date),
    month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  onFormChange() {
    this.searchForm.valueChanges.subscribe(val => {
      if (val.tour_id != this.tour_id) {
        console.log(
          `tour_id changed from ${this.tour_id} to ${val.tour_id}`
        )
        console.log(val);
        this.setTour(val.tour_id);
        // this.query.next({field: 'tour_id', tour_id: val.tour_id });
        let tmp_trip = val.trip_id? val.trip_id : '';
        this.searchForm.setValue({trip_id: tmp_trip,field: 'tour_id', tour_id: val.tour_id});
      } else {
        this.query.next({field: 'tour_trip', tour_id: val.tour_id, trip_id: val.trip_id });
        this.trip_id= val.trip_id;
      }
      // this.query.next(val);
    });
  }

  getTours() {
    console.log('getting tours');
    this.db.collection('world_tours').valueChanges().subscribe(tours => {
      this.tours = tours;
    })
  }

  setTour(tour_id: string) {
    this.tour_id = tour_id;
    this.db.collection(`world_tours/${tour_id}/trips`).valueChanges().subscribe(trips => {
      this.trips = trips;
    })
  }

  async hardSearch(wait: boolean= false) {
    let tmp_val = this.searchForm.value;
    const delay = ms => new Promise(_ => setTimeout(_, ms));
    if (wait) {
      await delay(.01).then(() => {})
    }
    this.query.next({field: 'tour_trip', tour_id: tmp_val.tour_id, trip_id: tmp_val.trip_id })
  }

  exportCSV() {
    console.log('exporting');
    this.bookings$.pipe(
      first(),
    ).subscribe(bookings => {
      const filename = `${this.tour_id}_${this.trip_id}_${this.formateDate(new Date())}.txt`;
      const blob = new Blob(this.bookingsToBlob(bookings), {type: 'text;charset=utf-8;'});
      console.log(filename);
      // console.log(blob);
      saveAs(blob, filename);
    })
    this.hardSearch();
  }

  bookingsToBlob(bookings: any[]) {
    var titleText = 'seqID,cs_id,trans_date,amount,extra_total,points,adults,children,tour_city,tour_country,trip_id';
    let blobArray = [titleText]
    let max_guests = 0;
    for (let booking of bookings) {
      let lineArray = [booking.seqID,booking.cs_id,booking.trans_date,booking.amount,booking.extra_total,booking.points,booking.adults,booking.children,booking.tour_info.city,booking.tour_info.country,booking.trip_info.trip_id];
      if (booking.traveler_info.length > max_guests) {
        max_guests = booking.traveler_info.length;
      }
      for (let traveler of booking.traveler_info) {
        lineArray.push(traveler.type,traveler.name,traveler.dob);
      }
      blobArray.push(lineArray.join(',')+'\n');
    }
    let tmp_a = Array.apply(null, Array(max_guests)).map(function (x, i) { return i; })

    for (let i of tmp_a) {
      titleText = titleText+',type'+String(i+1)+',name'+String(i+1)+',DOB'+String(i+1);
    }
    blobArray[0] = titleText + '\n';

    return blobArray;
  }

  specialBookingsToBlob(bookings: any[]) {
    let _bookings: any[] = [];
    for (let booking of bookings) {
      let _booking: any = {
        id: Number(booking.seqID),
        associate_id: Number(booking.cs_id),
        tour_slug: booking.tour_info.tour_id,
        trip_slug: booking.trip_info.trip_id,
        guests: JSON.stringify(booking.traveler_info),
        trans_date: booking.trans_date,
        amount: Number(booking.amount),
        points: Number(booking.points),
        extra_total: Number(booking.extra_total),
        adults: Number(booking.adults),
        children: Number(booking.children)
      }
      _bookings.push(_booking);
    }

    let blobArray: string[] = [];
    blobArray.push(JSON.stringify(_bookings))
    return blobArray
  }
}
