import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe, TranslateService } from '../../translate';
import * as $ from 'jquery';
import { CountryService } from '../../core/country.service';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { SponsorService } from '../../core/sponsor.service';

@Component({
  selector: 'app-launch',
  templateUrl: './launch.component.html',
  styleUrls: ['./launch.component.css']
})
export class LaunchComponent implements OnInit {
  zone: string;
  sponsor: string;
  sID: string;
  constructor(
    private cserv: CountryService,
    private trans: TranslateService,
		private route: ActivatedRoute,
		private db: AngularFirestore,
    private router: Router,
  ) { }

  ngOnInit() {
  }

}
