import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { ViewZonesComponent } from './view-zones/view-zones.component';
import { EditZoneComponent } from './edit-zone/edit-zone.component';
import { NewZoneComponent } from './new-zone/new-zone.component';
import { LoginComponent } from './login/login.component';
import { DashComponent } from './dash/dash.component';
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { WorldToursComponent } from './world-tours/world-tours.component';
import { TourEditComponent } from './tour/tour.component';
import { TripEditComponent } from './trip/trip.component';
import { BookingsComponent } from './bookings/bookings.component';
import { ImportBookingsComponent } from './import-bookings/import-bookings.component';
import { EditBookingComponent } from './edit-booking/edit-booking.component';
import { SpecialImportComponent } from './special-import/special-import.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxMaskModule.forRoot(),
  ],
  declarations: [ViewZonesComponent, EditZoneComponent, NewZoneComponent, LoginComponent, DashComponent, AdminHeaderComponent, WorldToursComponent, TourEditComponent, TripEditComponent, BookingsComponent, ImportBookingsComponent, EditBookingComponent, SpecialImportComponent]
})
export class AdminModule { }
