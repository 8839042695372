import { Component, OnInit, OnDestroy } from '@angular/core';
import { WorldtourService } from '../../core/worldtour.service';
import { IdService } from '../../core/id.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '../../translate';
import * as $ from 'jquery';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';


@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.css']
})
export class TourComponent implements OnInit, OnDestroy {
  selectedMonth: string | null = null;
  id: any;
  months: any[];
  upcomingMonths: any[];
  prevMonths: any[];
  loader: boolean = true;
  tour: any;
  timer: any;
  previous: boolean = false;
  now = new Date();

  constructor(
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private functions: AngularFireFunctions,
    private wts: WorldtourService,
    private auth: IdService,
    private router: Router
  ) {
    this.now = new Date();
    this.route.queryParams.subscribe(params => {
      if (params['id'] && params['hash']) {
        this.id = params['id'];
        if (!this.auth.verify(this.id,params['hash'])) {
          console.log('Failed Verification')
          window.location.replace("https://backoffice.travorium.com/travorium/member_new2/welcome.asp");
        }
        console.log('Pass Verification');

      } else if (auth.salted) {
        console.log('Previously Salted');
        this.id = this.auth.id
      } else {
        window.location.replace("https://backoffice.travorium.com/travorium/member_new2/welcome.asp");
      };

      this.route.params.subscribe((prams) => {

        if (prams['tour-id']) {
          this.tour = this.wts.serveTour(prams['tour-id'])
          this.monthCounts()
          if (params['month']) {
            this.selectMonth(params['month'])
          }
        }
      })
    });
  }

  ngOnInit() {

      $(function() {
        $('#slideshow div:gt(0)').hide();
      })

      this.timer = setInterval(() => {
        $('#slideshow :first-child')
        .fadeOut(1000)
        .next('div')
        .fadeIn(1000)
        .end()
        .appendTo('#slideshow');
        }, 5100);



  }

  ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  selectMonth(month: string) {
    this.selectedMonth = month
  }

  filterTripsByMonth(month: string | null, upcoming: boolean): any[] {
    let now = new Date();
    if (month) {
      let _trips = this.tour.trips.filter((trip) => {
        if (trip.month == month) {
          let now = new Date();
          return (trip.start_date_sortable >= now) ? upcoming: !upcoming
        } else return false
      })
      return _trips.filter((trip) => {
        let finalpaymentdue = new Date(Date.parse(trip.finalpaymentdue) + 28800000);
        return trip.rooms.reduce((acc, room) => {
          if (!room.active && finalpaymentdue > now) {
            return acc + false
          } return acc + true
        }, 0) > 0 ? true : false
      })
    } else {
      let _trips =  this.tour.trips.filter(trip => trip.start_date_sortable >= new Date()? upcoming: !upcoming)
      return _trips.filter((trip) => {
        let finalpaymentdue = new Date(Date.parse(trip.finalpaymentdue) + 28800000);
        return trip.rooms.reduce((acc, room) => {
          if (!room.active && finalpaymentdue > now) {
            return acc + false
          } return acc + true
        }, 0) > 0 ? true : false
      })
    }
  }

  getMonthsFromTrips(trips: any[]) {
    let months = [];
    let now = new Date();
    while (trips.length > 0) {
      console.log(trips);
      const month = trips[0].month
      const tmp_len = trips.length
      let finalpaymentdue = new Date(Date.parse(trips[0].finalpaymentdue) + 28800000);
      console.log(finalpaymentdue);
      console.log(now);
      const flag = trips[0].rooms.reduce((acc, room) => {
          console.log(room);
          if (!room.active && finalpaymentdue > now) {
          console.log("inside Here");
            return acc + false
          } return acc + true
      }, 0) > 0 ? true : false;
      console.log(trips);
      trips = trips.filter(trip => trip.month != trips[0].month)
      console.log(trips);
      if (flag) {
        months.push({name: month, count: tmp_len - trips.length})
      }
    }
    return months
  }

  viewPrevious(status) {
    if(status == 'yes') {
      $('#previous').addClass('active')
      $('#upcoming').removeClass('active')

      this.previous = true;
      this.months = this.prevMonths;
      this.selectedMonth= null;
    } else {
      $('#upcoming').addClass('active')
      $('#previous').removeClass('active')

      this.previous = false;
      this.months = this.upcomingMonths;
      this.selectedMonth= null;
    }

  }

  monthCounts() {
    let upcomingTrips = this.tour.trips.filter(trip => trip.start_date_sortable >= new Date())
    let pastTrips = this.tour.trips.filter(trip => trip.start_date_sortable < new Date())
    this.upcomingMonths = this.getMonthsFromTrips(upcomingTrips)
    this.months = this.upcomingMonths
    this.prevMonths = this.getMonthsFromTrips(pastTrips)
  }

  checkSO(rooms: any[]): boolean {
    let filterd = rooms.filter((room) => {return room.full});
    if (filterd.length >= rooms.length) {
      return true
    } else {
      return false
    }
  }

}
