import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'angular2-cookie/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { SponsorInfo } from './sponsor-info'

@Injectable({
  providedIn: 'root'
})
export class SponsorService {
  private _sponsorInfo: BehaviorSubject<SponsorInfo> = new BehaviorSubject(null)
  private _badSpons: BehaviorSubject<boolean> = new BehaviorSubject(false)
  private _touched: BehaviorSubject<boolean> = new BehaviorSubject(false)

  constructor(
    private afs: AngularFireFunctions,
    private router: Router,
    private route: ActivatedRoute,
    private cookie: CookieService
  ) {
    this._sponsorInfo.next(this.getSponsorCookie())
   }

   get sponsorInfo() {
     return this._sponsorInfo.asObservable();
   }

   get sponsorID() {
     const tmp_spons = this._sponsorInfo.getValue()
     if (tmp_spons) {
       return tmp_spons.sponsorID
     } else {
       return null
     }
   }

   get badSpons() {
     return this._badSpons.asObservable();
   }

   get touched() {
     return this._touched.asObservable();
   }

   checkSponsor(sID: string, zone?: string) {
     if (zone) {
       console.log('zoned')
     }
     const tmp_sponsor: SponsorInfo = this._sponsorInfo.getValue()
     if (tmp_sponsor) {
       if (sID === tmp_sponsor.sponsorID) {
         // console.log('cool')
       } else {
         console.log(zone)
         this.verify(sID, zone)
       }
     } else {
       this.verify(sID, zone)
     }
   }

   checkCookie() {
     console.log('checkCookie')
     const tmp_sponsor: SponsorInfo = this._sponsorInfo.getValue()
     if (tmp_sponsor) {
       this.router.navigateByUrl(this.router.url + '/' +tmp_sponsor.sponsorID)
     }

   }

   async verify(sponsorID: string, zone?: string) {
     console.log('checking')
     const scheck = this.afs.httpsCallable('sponsorCheck');
     scheck({sponsorid: sponsorID})
      .toPromise()
      .then(resp => {
        const sponsorString = <string>resp;
        const sponsorArray = sponsorString.split('|')
        if (sponsorArray[0] === '0'){
          this._badSpons.next(false)
          this._touched.next(true)
          this.setSponsor(sponsorArray)
        } else {
            this._badSpons.next(true)
            console.log('Bad Sponsor '+sponsorID)
            if (true) {
              const _path = this.router.url
              console.log(_path);
              var chopedPath = _path.substr(0, (_path.length - sponsorID.length))
              console.log('Chocpped '+ chopedPath)
              this.router.navigateByUrl(chopedPath)
            } else {
              console.log('didnt go in there')
              this.router.navigateByUrl('')
            }

        }
      })
      .catch(err => {
        console.error({ err });
      });
   }

   setSponsor(sarray: string[]) {
     const sinfo: SponsorInfo = {
        sponsorID: sarray[1],
        sponsorName: sarray[2],
        sponsorPhone: sarray[3],
        sponsorEmail: sarray[4],
        showName: sarray[5] === 'True',
        showEmail: sarray[6] === 'True',
        showPhone: sarray[7] === 'True',
     }

     this._sponsorInfo.next(sinfo)
     this.setSponsorCookie(sinfo)
   }

   setSponsorCookie(sinfo: SponsorInfo) {
     console.log('I Should set a COOKIE like Im a monster')
     this.cookie.putObject('SponsorInfo', sinfo)
   }

   getSponsorCookie(): SponsorInfo {
     const sponsorString = this.cookie.get('SponsorInfo')
     if (sponsorString) {
       console.log('returning sponsor cookie')
       return JSON.parse(sponsorString)
     } else {
       return null
     }
   }

   gaurdVerify(sponsorID: string): Promise<boolean> {
     const scheck = this.afs.httpsCallable('sponsorCheck');
     return scheck({sponsorid: sponsorID})
      .toPromise()
      .then(resp => {
        const sponsorString = <string>resp;
        const sponsorArray = sponsorString.split('|')
        if (sponsorArray[0] === '0'){
          this._badSpons.next(false)
          this._touched.next(true)
          this.setSponsor(sponsorArray)
          console.log('Returning True');
          return true
        } else {
            console.log('Bad Sponsor in guard '+sponsorID)
            return false
        }
      })
      .catch(err => {
        console.error({ err });
        return false
      });
   }

   reached() {
     this._touched.next(true)
   }

   checkSponsorJoin(sID: string) {
     this.verifyJoin(sID)
   }

   async verifyJoin(sponsorID: string) {
     console.log('checking')
     const scheck = this.afs.httpsCallable('sponsorCheck');
     scheck({sponsorid: sponsorID})
      .toPromise()
      .then(resp => {
        const sponsorString = <string>resp;
        const sponsorArray = sponsorString.split('|')
        if (sponsorArray[0] === '0'){
          this._badSpons.next(false)
          this._touched.next(true)
          this.setSponsor(sponsorArray)
        } else {
            this._badSpons.next(true)
            console.log('Bad Sponsor '+sponsorID)
        }
      })
      .catch(err => {
        console.error({ err });
      });
   }
}
